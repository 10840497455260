import { ReactNotifications, Store } from 'react-notifications-component'

export default function Notify(){
    return (
        <>
            <ReactNotifications />
        </>
    )
}

export const notify = (type, title, message, opts)=>{
    if(!opts) opts = {};
    if(!opts.timer) opts.timer = 2000
    if(!opts.container) opts.container = "top-right"
    Store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "top",
        container: opts.container,
        //animationIn: ["animate__animated", "animate__fadeIn"],
        //animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: opts.timer,
            onScreen: false
        }
    });
}