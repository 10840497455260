import React, {useEffect, useRef} from "react";
import { Controller } from "react-hook-form";
import Inputmask from "inputmask";


const RHFInputMask = ({name, control, defaultValue = "", rules = {}, mask, className}) => {

    const inputMaskRef = useRef();

    useEffect(() => {
        if (inputMaskRef.current) {
            const im = new Inputmask(mask);
            im.mask(inputMaskRef.current);
        }
    }, []);

    return (
        <Controller
            render={({ field }) => (
                <input
                    {...field}
                    ref={inputMaskRef}
                    className={className}
                />
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
        />

    );
};
export default RHFInputMask;