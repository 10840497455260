import React, {useEffect, useContext, useState, useRef} from 'react'
import {useTranslation} from "react-i18next";
import AuthContext from "../../context/AuthContext";
import {moduleControl} from "../../control/Module";
import Loader from "../Loader";
import Tabela from "../Tabela";
import RHFCpf from "../forms/RHFCpf";
import {Button} from "react-bootstrap";
import {useForm, FormProvider} from "react-hook-form";
import RHFMuiSelect from "../forms/RHFMuiSelect";
import ServicoContext from "../../context/ServicoContext";
import {notify} from "../Notify";

const Inscritos = props => {
    const {t} = useTranslation();
    const {user} = useContext(AuthContext);

    const [loader, setLoader] = useState({show:true,msg:t('carregando')})
    const {servicos} = useContext(ServicoContext);
    const [data, setData] = useState([]);
    const [optionsServicos, setOptionsServicos] = useState([])
    const [optionsVaga, setOptionsVaga] = useState([])
    const [servicoMap, setServicoMap] = useState([])

    const tabelaRef = useRef();

    const methods = useForm();
    const {register, control, watch} = methods;

    useEffect(()=>{
        setLoader({show:false,msg:''})
    },[])

    useEffect(()=>{
        const list = []
        const map = {}
        if(servicos){
            list.push({value:"",name:t('todos')})
            for(const [,servico] of servicos.entries()){
                if(servico._module_options && servico._module_options.cadastro) continue;
                if(servico._perm && servico._perm.editar){
                    map[servico._id] = servico;
                    list.push({value:servico._id,name:servico.nome})
                }
            }
            setServicoMap(map);
            setOptionsServicos(list);
            setLoader({show:false,msg:''})
        }
    },[servicos])

    const columns = [
        { title: t('cpf'), field: "cpf"},
        { title: t('inscricao'), field: "uuid"},
        { title: t('processo'), field: "_servico_codigo" },
        { title: t('cadastro'), field: "_vaga_desc" },
        { title: t('data_inscricao'), field: "data", formatter:"datetime", formatterParams:{
                inputFormat:"iso",
                outputFormat:"dd/MM/yyyy HH:mm",
                invalidPlaceholder:"(invalid date)",
                timezone:"utc"}
        },
        { title: t('uf'), field: "_vaga_uf" },
        { title: t('cidade'), field: "_vaga_cidade" },
        { title: t('pontos'), field: "pontos", hozAlign:"center", vertAlign:"middle" },
    ];

    const watchServico = watch('servico');
    useEffect(()=>{
        const list = []
        if(watchServico && watchServico.trim() !== '' && servicoMap[watchServico] && servicoMap[watchServico]._module_vagas){
            list.push({value:"",name:t('todos')})
            for(const id of Object.keys(servicoMap[watchServico]._module_vagas)){
                list.push({value:id, name:servicoMap[watchServico]._module_vagas[id].codigo})
            }
        }
        setOptionsVaga(list);
    },[servicoMap, watchServico])

    const onSubmit = async (data, e)=>{
        setLoader({show:true,msg:t('carregando')})

        const filter = {}
        let idServico = null;
        if(data.servico && data.servico.trim() !== '') idServico = data.servico
        if(data.vaga && data.vaga.trim() !== '') filter.vaga = data.vaga

        if(data.cpf) filter.cpf = data.cpf
        if(data.uuid) filter.uuid = data.uuid

        const result = await moduleControl.relatorio(user.token, {cpf:'$cpf', vaga:'$vaga'}, filter, idServico);
        setData(result);
        setLoader({show:false,msg:''})
    }

    return (
        <>
            <Loader show={loader.show} msg={loader.msg} type="grid" />
            <div className="row">
                <div className="col-md-10">
                    <FormProvider {...methods} >
                        <form key="cadastro" onSubmit={methods.handleSubmit(onSubmit)}>
                            <div className="card mt-2 mb-2">
                                <div className="card-header bg-light p-2">
                                    <span className="card-title font-weight-semibold">{t('cadastro')}</span>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-1">
                                                <label>{t('processo')}</label>
                                                <RHFMuiSelect
                                                    name="servico"
                                                    label={t('selecione_o',{item:t('processo')})}
                                                    control={control}
                                                    options={optionsServicos}
                                                    defaultValue=""/>
                                            </div>
                                            <div className="form-group mb-1">
                                                <label>{t('cadastro')}</label>
                                                <RHFMuiSelect
                                                    name="vaga"
                                                    label={t('selecione_o',{item:t('cadastro')})}
                                                    control={control}
                                                    options={optionsVaga}
                                                    defaultValue=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-2">
                                                <label>{t('cpf')}</label>
                                                <RHFCpf className="form-control" name="cpf" showError={false}/>
                                            </div>
                                            <div className="form-group mb-2">
                                                <label>{t('inscricao')}</label>
                                                <input type="text" className="form-control" placeholder="" {...register("uuid")} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-0 mt-2">
                                                <Button type="submit"  variant="primary">{t('pesquisar')}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Tabela
                        name={t('inscricoes')}
                        ref={tabelaRef}
                        data={data}
                        columns={columns} />
                </div>
            </div>
        </>
    )
}

export default Inscritos