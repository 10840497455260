import { createRoot } from 'react-dom/client';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import App from "./App";
import ptBR_i18n from "./locales/pt_br.json"
import en_i18n from "./locales/en.json"

i18n.use(initReactI18next).init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            pt_br: {
                translation: ptBR_i18n
            },
            en: {
                translation: en_i18n
            }
        },
        lng: "pt_br",
        fallbackLng: "pt_br",
        interpolation: {
            escapeValue: false
        }
    });

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App/>);