import React, {useState, useEffect, useRef, useContext, forwardRef, useImperativeHandle} from "react";
import Loader from "./Loader";
import {useTranslation} from "react-i18next";
import moment from 'moment';

import AuthContext from "../context/AuthContext";
import ServicoContext from "../context/ServicoContext";
import ModalEditarProcesso from "./ModalEditarProcesso";
import ModalNovoProcesso from "./ModalNovoProcesso";
import ModalAbrirProcessoFase1 from "./ModalAbrirProcessoFase1";
import ModalAbrirProcessoFase2 from "./ModalAbrirProcessoFase2";

import {Tooltip} from '@mui/material';
import Swal from 'sweetalert2'
import '../styles/sweetalert.css'
import {moduleControl} from "../control/Module";
import ModalCadastrarVagas from "./ModalCadastrarVagas";


const ProcessoBox = forwardRef((props, ref) => {

    const {t} = useTranslation();
    const {user} = useContext(AuthContext);
    const {remover:removerServico} = useContext(ServicoContext);

    useImperativeHandle(
        ref,
        () => ({
            setLoader(show) {
                setLoader({show:show,msg:''})
            }
        }),
    )

    const [loader, setLoader] = useState({show:false, msg:''})
    const [servico, setServico] = useState(null);
    const [boxCss, setBoxCSS] = useState('box-header')
    const [invalid, setInvalid] = useState(false);
    const [inscritos, setInscritos] = useState(0);

    useEffect(()=>{
        setLoader({show:true, msg:t('carregando')})
        if(props.servico._public){
            setBoxCSS('box-header')
        }else{
            setBoxCSS('box-header box-private')
        }
        if(props.servico._invalid){
            setBoxCSS('box-header box-invalid')
            setInvalid(true);
        }
        setServico(props.servico);

        const buscarInscritos = async ()=>{
            const rs = await moduleControl.countDocumentos(props.servico.tab,{},{cpf:'$cpf'});
            if(rs && !rs.error){
                setInscritos(rs.count);
            }else{
                setInscritos(0);
            }
        }
        buscarInscritos().then(()=>{
            setLoader({show:false, msg:''})
        }).catch(err=>{
            setLoader({show:false, msg:''})
        })
    },[props.servico])

    const $box = useRef('box')

    useEffect(()=>{
        setLoader({show:props.loading, msg:''})
    },[props.loading])

    //EDITAR PROCESSO
    const [editarProcessoModalShow, setEditarProcessoModalShow] = useState(false);
    const editarProcessoModalOpen = ()=>{
        setEditarProcessoModalShow(true);
    }
    const editarProcessoModalClose = ()=>{
        setEditarProcessoModalShow(false);
    }

    //EDITAR NOVO PROCESSO
    const [novoProcessoModalShow, setNovoProcessoModalShow] = useState(false);
    const novoProcessoModalOpen = ()=>{
        setNovoProcessoModalShow(true);
    }
    const novoProcessoModalClose = ()=>{
        setNovoProcessoModalShow(false);
    }

    //CADASTRAR VAGAS
    const [cadastrarVagasModalShow, setCadastrarVagasModalShow] = useState(false);
    const cadastrarVagasModalOpen = ()=>{
        setCadastrarVagasModalShow(true);
    }
    const cadastrarVagasModalClose = ()=>{
        setCadastrarVagasModalShow(false);
    }

    //ABRIR PROCESSO FASE 1
    const [abrirProcessoFase1ModalShow, setAbrirProcessoFase1ModalShow] = useState(false);
    const abrirProcessoFase1ModalOpen = ()=>{
        setAbrirProcessoFase1ModalShow(true);
    }
    const abrirProcessoFase1ModalClose = ()=>{
        setAbrirProcessoFase1ModalShow(false);
    }

    //ABRIR PROCESSO FASE 2
    const [abrirProcessoFase2ModalShow, setAbrirProcessoFase2ModalShow] = useState(false);
    const abrirProcessoFase2ModalOpen = ()=>{
        setAbrirProcessoFase2ModalShow(true);
    }
    const abrirProcessoFase2ModalClose = ()=>{
        setAbrirProcessoFase2ModalShow(false);
    }

    const abrirProcessoModal = (event)=>{
        if(servico._module_options.fase === 1){
            abrirProcessoFase1ModalOpen(event)
        }else if(servico._module_options.fase === 2){
            abrirProcessoFase2ModalOpen(event)
        }else{
            abrirProcessoFase1ModalOpen(event);
        }
    }

    const remover = async ()=>{
        const pop = await Swal.fire({
            title: t('info_voce_tem_certeza'),
            text: t('info_processo_sera_removido'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('sim'),
            cancelButtonText: t('cancelar')
        })
        if(pop.isConfirmed){
            setLoader({show:true, msg: t('removendo')})
            await removerServico(servico._id)
            setLoader({show:false, msg: ''})
        }
    }

    if(!servico){
        return <></>
    }

    return (
        <>
            <div ref={$box}>
                <div className="box-servico">
                    <Loader show={loader.show} msg={loader.msg} error={loader.error} type="box"/>
                    {(invalid) ?
                        <div className={boxCss}>
                            <div className="box-nome font-size-lg font-weight-bold">{servico.nome}</div>
                            <div className="box-descricao font-size-xs">{servico.descricao}</div>
                            <div className="box-descricao font-size-xs text-muted">{t('info_inscricoes_encerradas')}</div>
                        </div> :
                        <div
                            className={boxCss}
                            onClick={(event)=>{abrirProcessoModal(event)}}>
                            <div className="box-nome font-size-lg font-weight-bold">{servico.nome}</div>
                            <div className="box-descricao font-size-xs">{servico.descricao}</div>
                            <div className="box-descricao font-size-xs text-muted">{'* '+t('info_clique_para_inscrever')}</div>
                        </div>
                    }

                    <div className="box-info">
                        <div className="box-count font-size-xs">
                            <span className="font-weight-bold">{t('validade')+': '}</span>
                            {(servico._module_options.validade) ?
                                moment(servico._module_options.validade,'YYYYMMDDHHmmss').format('DD/MM/YYYY HH:mm') : '--'}
                        </div>
                        {(servico._adm) ?
                            <div className="box-count font-size-xs mt-1">
                                <span className="font-weight-bold">{t('inscritos')+': '}</span>
                                <span className="font-weight-bold text-success">{inscritos}</span>
                            </div>:''}
                    </div>
                    <div className="box-action d-flex" style={{minHeight:'55px'}}>
                        <div className="w-100 text-sm-right">
                            {(servico._perm && servico._perm.editar) ?
                                <Tooltip title={t('editar_processo')} arrow>
                                    <button type="button" onClick={(event)=>novoProcessoModalOpen(event)}
                                        className="btn btn-sm btn-light btn-icon mr-1"><i className="icon-pencil"></i></button>
                                </Tooltip>:''}
                            {(servico._perm && servico._perm.editar) ?
                                <Tooltip title={t('configurar_processo')} arrow>
                                    <button type="button" onClick={(event)=>editarProcessoModalOpen(event)}
                                        className="btn btn-sm btn-light btn-icon mr-1"><i className="icon-gear"></i></button>
                                </Tooltip>:''}
                            {(servico._perm && servico._perm.editar) ?
                                <Tooltip title={t('cadastrar_vagas')} arrow>
                                    <button type="button" onClick={(event)=>cadastrarVagasModalOpen(event)}
                                            className="btn btn-sm btn-light btn-icon mr-1"><i className="icon-plus3"></i></button>
                                </Tooltip>:''}
                            {(servico._adm) ?
                                <Tooltip title={t('remover_processo')} arrow>
                                    <button type="button" onClick={(event)=>remover(event)}
                                        className="btn btn-sm btn-danger-100 btn-icon"><i className="icon-trash"></i></button>
                                </Tooltip>:''}
                        </div>
                    </div>
                    <div className="box-loading">
                        <div className="box-loading-img">
                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalAbrirProcessoFase1
                show={abrirProcessoFase1ModalShow}
                close={abrirProcessoFase1ModalClose}
                servico={servico} />

            <ModalAbrirProcessoFase2
                show={abrirProcessoFase2ModalShow}
                close={abrirProcessoFase2ModalClose}
                servico={servico} />

            <ModalNovoProcesso
                show={novoProcessoModalShow}
                close={novoProcessoModalClose}
                servico={servico} />

            <ModalCadastrarVagas
                id={"vagas."+servico.tab}
                show={cadastrarVagasModalShow}
                close={cadastrarVagasModalClose}
                servico={servico} />

            <ModalEditarProcesso
                close={editarProcessoModalClose}
                show={editarProcessoModalShow}
                servico={servico}/>
        </>
    )
})

export default ProcessoBox