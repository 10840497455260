import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import { Controller } from 'react-hook-form';
import {InputNumber} from "primereact/inputnumber";


const RHFInputNumber = forwardRef((props, ref) => {

    useImperativeHandle(
        ref,
        () => ({

        }),
    )

    const {name, placeholder, control, handleChange, defaultValue, minFractionDigits= 0, maxFractionDigits= 0} = props;

    return (
        <Controller

            name={name}
            control={control}
            defaultValue={defaultValue}

            render={({ field, fieldState }) => (

                <InputNumber
                    id={field.name}
                    value={field.value || parseInt("0")}
                    onValueChange={(e) => field.onChange(e.value)}
                    ref={field.ref}
                    className={`p-inputtext-sm ${fieldState.invalid ? 'p-invalid' : ''}`}
                    style={{width:"100%"}}
                    placeholder={placeholder}
                    minFractionDigits={minFractionDigits}
                    maxFractionDigits={maxFractionDigits}
                />
            )}

        />
    );
})

export default RHFInputNumber;