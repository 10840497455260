import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState, forwardRef, useRef, useImperativeHandle} from "react";
import AuthContext from "../../../context/AuthContext";
import ServicoContext from "../../../context/ServicoContext";

import Loader from '../../Loader';
import {Button, Modal} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";

import { Toast } from 'primereact/toast';
import FormField from "../FormField";

const ModalNew = forwardRef((props, ref) => {

    const {id, title, color, focus, configuration= {}, validation=async (item)=>{return {}}, action={}} = props;

    const {t} = useTranslation();

    const {user} = useContext(AuthContext);
    const {salvarCadastro} = useContext(ServicoContext);

    const [loader, setLoader] = useState({show:false, msg:'', error:false})
    const [showModal, setShowModal] = useState(props.show);
    const [configMap, setConfigMap] = useState({});
    const [table, setTable] = useState([]);
    const [colSize, setColSize] = useState([]);

    useImperativeHandle(
        ref,
        () => ({
            setLoader(show, msg, error) {
                setLoader({show, msg, error})
            }
        }),
    )

    const inputRefs = useRef({});
    const toast = useRef(null);

    useEffect(()=>{
        setShowModal(props.show);
    },[props.show])

    useEffect(() => {
        if(configuration){
            setTable((configuration.table) ? configuration.table : []);
        }
    }, [configuration]);

    useEffect(() => {
        const map = {}
        let ncols = 1;
        for(const [,item] of table.entries()){
            map[item.field] = item;
            if(item._col && item._col > 1 && item._col > ncols) {
                ncols = item._col;
            }
        }
        setColSize(ncols);
        setConfigMap(map);
    }, [table]);

    const methods = useForm();
    const {
        register,
        control,
        watch,
        handleSubmit,
        reset,
        setFocus,
        setValue,
        setError,
        clearErrors,
        formState: { errors }
    } = methods;

    const salvarAPI = async (entity)=>{
        return await salvarCadastro(id, configuration, entity)
    }

    const onShow = ()=>{
        setFocus(focus, { shouldSelect: true })
        if(props.onShow) props.onShow()
    }
    const onExited = ()=>{
        reset();
        setLoader({show:false, msg:'', error: false})
        if(props.onClose) props.onClose();
    }

    const onSubmit = (data, e) => {
        //console.log(data); return;
        const salvar = async ()=>{

            let hasError = false;
            for(const field of Object.keys(configMap)){
                const conf = configMap[field];

                let value = "";
                if(conf._type === 'list' || conf._type === 'reference'){
                    value = (data[field]) ? data[field].value : "";
                }else{
                    value = data[field]+"";
                }
                if(conf.hasOwnProperty('_mandatory') && conf._mandatory && (!value || value.trim() === '')){
                    setError(field, { type:"manual", message:t('info_campo_vazio',{campo:t(field)}) })
                    hasError = true;
                }
            }

            const errorMap =  await validation(data);
            if(errorMap && Object.keys(errorMap).length > 0){
                for(const key of Object.keys(errorMap)){
                    setError(key, { type:"manual", message:errorMap[key] })
                }
                hasError = true;
            }

            if(hasError) return false;

            const entity = {}
            for(const key of Object.keys(data)){
                const conf = configMap[key];
                if(conf._type === 'list' || conf._type === 'reference'){
                    if(conf._multiple){
                        entity[key] = (data[key] && Array.isArray(data[key])) ? data[key] : [];
                    }else{
                        entity[key] = (data[key]) ? data[key].value : "";
                    }
                }else{
                    entity[key] = data[key];
                }
            }
            return await salvarAPI(entity)
        }

        setLoader({show:true, msg:t('salvando'), error: false})
        salvar().then((rs)=>{
            setLoader({show:false, msg:'', error: false})
            if(!rs || rs.error){
                toast.current.show({severity:'error', summary: t('salvando'), detail:t('error_salvar'), life: 2000});
                return;
            }
            if(props.onSave){
                props.onSave(rs);
            }
            props.close();
        }).catch(err=>{
            setLoader({show:false, msg:'', error: false})
            console.error(err);
        })

    }

    const handleSelectChange = (name, selectedValue, origin)=>{}

    return (
        <>
            <Modal
                show={showModal}
                onExited={onExited}
                onShow={onShow}
                animation={true}
                dialogClassName={(colSize <= 1) ? "" : "modal-lg"}
            >
                <Loader show={loader.show} msg={loader.msg} type="modal" />
                <Toast ref={toast} appendTo={"self"} position="bottom-center" />
                <form key="modalNew" onSubmit={methods.handleSubmit(onSubmit)}>
                    <Modal.Header className="p-2 pl-3" style={(color) ? {backgroundColor:"#"+color+"2b"} : {backgroundColor:"#f0f2f5"}}>
                        <span className="font-weight-semibold">{(title) ? title : t('novo')}</span>
                    </Modal.Header>
                    <Modal.Body>
                        <FormProvider {...methods} >
                            <div className="row">
                                <div className={(colSize <= 1) ? "col-md-12" : "col-md-6"}>
                                    {table.map((item, index) => {
                                        if (item.field.startsWith('_')) return true;
                                        if (item._col && item._col > 1) return true;

                                        return (
                                            <FormField
                                                key={"form-field-"+index}
                                                id={id}
                                                index={index}
                                                tableItem={item}
                                                handleSelectChange={handleSelectChange}
                                                inputRef={(el) => inputRefs.current[item.field] = el}
                                            />
                                        )

                                    })}
                                </div>
                                <div className="col-md-6" style={(colSize <= 1) ? {display:"none"}:{display:"block"}}>
                                    {table.map((item, index) => {
                                        if (item.field.startsWith('_')) return true;
                                        if (!item._col || item._col < 2) return true;

                                        return (
                                            <FormField
                                                key={"form-field-"+index}
                                                id={id}
                                                index={index}
                                                tableItem={item}
                                                handleSelectChange={handleSelectChange}
                                                inputRef={(el) => inputRefs.current[item.field] = el}
                                            />
                                        )

                                    })}
                                </div>
                            </div>
                        </FormProvider>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">{t('salvar')}</Button>
                        <Button variant="light" onClick={props.close}>{t('fechar')}</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )

})

export default ModalNew