import React from 'react'

const ErrorPage = ({msg}) => {
    return (
        <div className="text-danger text-center pt-5">
            <p className="text-center">{msg}</p>
            :(
        </div>
    )
}

export default ErrorPage