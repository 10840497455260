import React, {forwardRef, useImperativeHandle, useState, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";

import tableLang from '../../locales/tabulator.loc.json'

import {TabulatorFull as Tabulator} from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";

import {useForm} from "react-hook-form";
import RHFSelect from "../forms/RHFSelect";
import moment from "moment";
import Loader from "./../Loader";

const TableCrud = forwardRef((props, ref) => {

    const {t} = useTranslation();
    const {
        name,
        color,
        configuration,
        data = [],
        columns= [],
        index = "_id",
        editMode = false,
        initialSort,
        initialFilter,
        groupBy,
        contextMenu,
        groupContextMenu,
        rowSelectionChanged,
        onCellEdited,
        rowAdded,
        rowClick,
        pagination = false,
        defaultPagination = 25,
        rowFormatter,
        selectableRowsCheck,
        persist = false
    } = props;


    const [tabulator, setTabulator] = useState(null);
    const tabulatorRef = useRef();

    const [configMap, setConfigMap] = useState({})

    const [selectedCount, setSelectedCount] = useState(0)
    const [rowCount, setRowCount] = useState(0)

    useEffect(() => {
        if(configuration && configuration.table){
            const map = {}
            for(const [,item] of configuration.table.entries()){
                map[item.field] = item;
            }
            setConfigMap(map);
        }
    }, [configuration]);

    useImperativeHandle(
        ref,
        () => ({
            tabulator() {
                return tabulator;
            },
            sort(field, direction) {
                if(tabulator) tabulator.setSort(field, direction);
            },
            getSelectedData(){
                return (tabulator) ? tabulator.getSelectedData() : null
            },
            getEditedCells() {
                return (tabulator) ? tabulator.getEditedCells() : []
            },
            isEditMode(){
                return editMode;
            }
        }),
    )

    let rowContextMenu = []
    if(contextMenu){
        for(const [,menu] of contextMenu.entries()){
            rowContextMenu.push({
                label: menu.label,
                action: (e, row)=>{
                    if(menu.action) menu.action(e, row)
                }
            })
        }
    }
    let rowGroupContextMenu = []
    if(groupContextMenu){
        for(const [,menu] of groupContextMenu.entries()){
            rowGroupContextMenu.push({
                label: menu.label,
                action: (e, group)=>{
                    if(menu.action) menu.action(e, group)
                }
            })
        }
    }

    const options = {
        index,
        selectableRows:true,
        selectableRowsCheck:(row)=>{
            return (selectableRowsCheck) ? selectableRowsCheck(row) : true
        },
        height:"100%",
        data:[],
        initialFilter,
        groupBy,
        columnHeaderVertAlign:"bottom",
        clipboard:true,
        clipboardCopyRowRange:'selected',
        clipboardCopyStyled:false,
        clipboardCopyConfig:{
            columnHeaders:false,
            columnGroups:false,
            rowGroups:false,
            formatCells:true
        },
        placeholder:t('msg_nenhum_registro'),
        columns:[],
        selectableRowsRangeMode:"click",
        keybindings:{},
        pagination: 'local',
        paginationSize: defaultPagination,
        paginationSizeSelector: [5, 10, 25, 50, 100],
        //layout: "fitDataFill",
        layout: "fitColumns",
        //renderVertical:"basic",
        reactiveData:true,
        locale:'pt-br',
        langs:tableLang,
        addRowPos:'top',
        validationMode:"manual",
        rowContextMenu,
        groupContextMenu:rowGroupContextMenu,
        rowFormatter: (row)=>{
            const data = row.getData();
            if(rowFormatter) rowFormatter(row);
        }
    };

    if(!pagination){
        delete options.pagination;
        delete options.paginationSize;
        delete options.paginationSizeSelector;
    }else{
        options.paginationSize = defaultPagination
    }

    useEffect(()=>{
        const tabulatorInstance = new Tabulator(tabulatorRef.current, options);
        tabulatorInstance.on("rowSelectionChanged", (data, rows)=>{
            if(rowSelectionChanged) rowSelectionChanged(data, rows);
        });
        tabulatorInstance.on("rowAdded", (row)=>{
            if(rowAdded) rowAdded(row);
        });
        tabulatorInstance.on("rowClick", (e, row)=>{
            if(rowClick) rowClick(row);
        });
        tabulatorInstance.on("cellEditing", (cell)=>{
            //console.log(cell);
        });
        tabulatorInstance.on("cellEdited", (cell)=>{
            if(onCellEdited) onCellEdited(cell);
        });
        tabulatorInstance.on("tableBuilt", ()=>{
            tabulatorInstance.deselectRow();
            setTabulator(tabulatorInstance);
        });
        tabulatorInstance.on('rowSelectionChanged',(data, rows)=>{
            setSelectedCount(data.length);
        })
        tabulatorInstance.on('dataFiltered',(filters, rows)=>{
            setRowCount(rows.length);
        });
        tabulatorInstance.on('dataLoaded',(data)=>{
            setRowCount(data.length);
        });
        return () => {
            if (tabulatorInstance) {
                tabulatorInstance.destroy();
            }
            if (tabulator) {
                tabulator.destroy();
            }
        };
    },[])

    useEffect(()=>{
        if(tabulator && data && columns && columns.length > 0){
            //console.log('Loading Tabulator', name, data, columns, editMode);

            const copts = [];
            const mopts = {};
            const _columns = [
                //{title:"", field:"_row_index", maxWidth:50, headerSort:false}
            ];
            for(const [, item] of columns.entries()){
                if(item.hasOwnProperty('visible') && !item.visible) continue;
                copts.push({label: item.title, value:item.field})
                mopts[item.field] = item;

                const column = {...item}
                column.editor = (editMode) ? item.editor : false;
                _columns.push(column);
            }

            tabulator.options.selectableRows = !editMode;

            setColumnOptions(copts)
            setColumnMap(mopts);

            let selectedRow, currentPage;
            if(persist){
                const selectedRows = tabulator.getSelectedData();
                const page = tabulator.getPage();
                selectedRow = (selectedRows) ? selectedRows[0] : null;
                currentPage = (page) ? page : 1;
                if(selectedRow){
                    const rowToSelect = tabulator.getRows().find(row => row.getData()._id === selectedRow._id);
                    if (rowToSelect) {
                        rowToSelect.update({ _action_edit: 'loading' });
                        //rowToSelect.select();
                    }
                }
            }

            tabulator.setColumns(_columns);
            tabulator.setData(data);
            tabulator.setSort(initialSort);
            tabulator.redraw();

            if(persist && selectedRow){
                tabulator.setPage(currentPage);
                const rowToSelect = tabulator.getRows().find(row => row.getData()._id === selectedRow._id);
                if (rowToSelect) {
                    rowToSelect.select();
                }
            }
        }
    },[tabulator, data, columns, editMode])

    const [columnOptions, setColumnOptions] = useState([])
    const [columnMap, setColumnMap] = useState({});

    const {
        register,
        control,
        watch,
        handleSubmit,
        reset,
        setFocus,
        setValue,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm();
    const onSubmit = (data, e) => {}

    const handleFilter = (e) => {
        const searchColumn = watch("search-coluna");
        const searchOperator = watch("search-operador").value;
        let searchValue = e.target.value;
        if(!searchValue || searchValue.trim() === ''){
            tabulator.clearFilter();
            return;
        }
        //console.log(searchColumn, searchOperator, searchValue);
        if (searchColumn) {
            const conf = configMap[searchColumn.value];
            if(conf._type === "reference"){
                tabulator.setFilter((data) => {
                    return (
                        data[conf.field] &&
                        typeof data[conf.field] === 'object' &&
                        String(data[conf.field][conf._crud_label]).toLowerCase().includes(searchValue.toLowerCase())
                    )
                });
            }else{
                if(columnMap[searchColumn.value] && columnMap[searchColumn.value].formatter === 'datetime' && (searchOperator === '<=' || searchOperator === '>=')){
                    if(moment(searchValue,'DD/MM/YYYY HH:mm:ss').isValid()){
                        searchValue = moment(searchValue,'DD/MM/YYYY HH:mm:ss').utc(true).toISOString()
                    }else{
                        return;
                    }
                }
                tabulator.setFilter(searchColumn.value, searchOperator, searchValue);
            }
        } else {
            tabulator.setFilter((data) => {
                return Object.keys(data).some((key) => {
                    if(key.startsWith('_')) return false;
                    const conf = configMap[key];
                    if(!conf) return false;
                    if(conf._type === "reference" && typeof data[key] === 'object'){
                        return String(data[key][conf._crud_label]).toLowerCase().includes(searchValue.toLowerCase())
                    }else{
                        return String(data[key]).toLowerCase().includes(searchValue.toLowerCase())
                    }
                });
            });
        }
    };

    return (
        <>

            <div className="card mb-2">
                <div className={"card-header border-bottom p-2"} style={(color) ? {backgroundColor:"#"+color+"2b"} : {backgroundColor:"#f0f2f5"}}>
                    <span className="card-title font-weight-bold font-size-lg">{name}</span>
                </div>
                <div className="card-body border-bottom p-1">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group form-group-xs row mb-0">
                            <div className="col-4">
                                <RHFSelect
                                    name={"search-coluna"}
                                    placeholder={t('coluna')}
                                    control={control}
                                    isClearable={true}
                                    options={columnOptions}
                                />
                            </div>
                            <div className="col-2">
                                <RHFSelect
                                    name={"search-operador"}
                                    control={control}
                                    isClearable={false}
                                    defaultValue={{label: '=', value: 'like'}}
                                    options={[
                                        {label: '=', value: 'like'},
                                        {label: '==', value: '='},
                                        {label: '<>', value: '!='},
                                        {label: '>=', value: '>='},
                                        {label: '<=', value: '<='}
                                    ]}
                                />
                            </div>
                            <div className="col-4">
                                <div className="form-group form-group-feedback form-group-feedback-left mb-0">
                                    <input type="text" placeholder={t('filtro')} {...register("search-valor")} className="form-control" onKeyUp={handleFilter}/>
                                    <div className="form-control-feedback">
                                        <i className="icon-search4"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 text-right" style={{verticalAlign: "middle"}}>
                                <span><b>{t('total')}</b>: {rowCount} | <b>{t('selecionados')}</b>: {selectedCount}</span>
                            </div>
                        </div>
                    </form>
                </div>
                <div style={{position: "relative"}}>
                    <div ref={tabulatorRef}></div>
                </div>

            </div>

        </>
    )

})

export default TableCrud