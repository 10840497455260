import React, {useEffect, useState, useRef, forwardRef, useImperativeHandle, useContext} from "react";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import moment from 'moment';

import Loader from "./Loader";
import {useForm, FormProvider, useFormContext} from "react-hook-form";
import RHFMuiSelect from "./forms/RHFMuiSelect";
import RHFInputDocumento from "./forms/RHFInputDocumento";
import WizardForm from "./forms/wizard/WizardForm";
import RHFInputMask from "./forms/RHFInputMask";
import {notify} from "./Notify";
import {moduleControl} from "../control/Module";
import ComprovanteFase1 from "./ComprovanteFase1";
import {useReactToPrint} from "react-to-print";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import Swal from "sweetalert2";
import RHFCpf from "./forms/RHFCpf";
import RHFEmail from "./forms/RHFEmail";
import RHFSelect from "./forms/RHFSelect";
import ServicoContext from "../context/ServicoContext";
import {useWizard} from "react-use-wizard";
import {Divider} from "rsuite";

const Step1 = ({vaga, vagas, servico, handleChange, close}) => {
    const {t} = useTranslation();
    const { control } = useFormContext();

    const {nextStep} = useWizard();

    return (
        <>
            <div className="pl-3 pr-3">
                <div className="row">
                    <div className="col-md-12">
                        <fieldset>
                            <div className="form-group mb-0 mt-1">
                                <RHFMuiSelect
                                    name="vaga"
                                    label={t('selecione')}
                                    control={control}
                                    options={vagas}
                                    handleChange={handleChange}
                                    defaultValue=""/>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="row pt-3" style={(!vaga) ? {display:'none'} : {display:'block'}}>
                    <div className="col-md-6">
                        <div className="card border-left-3 border-left-primary rounded-left-0">
                            <div className="card-body">
                                <div className="d-sm-flex align-item-sm-center flex-sm-nowrap">
                                    <div>
                                        <h6 className="font-weight-semibold">{(vaga) ? vaga.codigo : ''}</h6>
                                        <ul className="list list-unstyled mb-0">
                                            <li><span className="text-muted">{(vaga) ? vaga.descricao : ''}</span></li>
                                            <li>{t('uf')}: <span className="font-weight-semibold">{(vaga) ? vaga.uf : ''}</span></li>
                                            {(vaga && vaga.cidade !== '') ? <li>{t('cidade')}: <span className="font-weight-semibold">{vaga.cidade}</span></li> : ''}
                                            {(vaga && vaga.bairro !== '') ? <li>{t('bairro')}: <span className="font-weight-semibold">{vaga.bairro}</span></li> : ''}
                                            <li>{t('validade')}: <span className="font-weight-semibold">
                                                {(servico._module_options.validade && servico._module_options.validade !== '') ?
                                                    moment(servico._module_options.validade,'YYYYMMDDHHmm').format('DD/MM/YYYY HH:mm') : '--'}
                                            </span></li>
                                        </ul>
                                    </div>
                                    <div className="text-sm-right mb-0 mt-3 mt-sm-0 ml-auto">
                                        <h6 className="font-weight-semibold">{servico._module_options.portaria}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer d-sm-flex justify-content-sm-between align-items-sm-center">
                                {t('vagas')+':'}
                                <span className="font-weight-semibold text-success">
                                    {(vaga) ? vaga.quantidade : 0}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Divider />
            <div className="row">
                <div className="col-md-12 text-right">
                    <div className="btn-group">
                        {(vaga) ? <button onClick={() => nextStep()} type="button" className="btn btn-success">{t('proximo')} <i className="icon-arrow-right5 ml-2"></i></button> : ""}
                    </div>
                    <div className="btn-group ml-2">
                        <Button variant="light" onClick={close}>{t('fechar')}</Button>
                    </div>
                </div>
            </div>
        </>
    );
};
const Step2 = ({servico, close, setLoader}) => {
    const {t} = useTranslation();
    const { register, control, setValue, setFocus, watch, formState: { errors }, resetField} = useFormContext();

    const {getStateCollection} = useContext(ServicoContext);
    const [cargosOptions, setCargosOptions] = useState([]);
    const [escolasOptions, setEscolasOptions] = useState([]);
    const [professoresMap, setProfessoresMap] = useState([]);

    const { previousStep, nextStep, activeStep, isFirstStep, isLastStep, stepCount, goToStep } = useWizard();

    useEffect(()=>{
        const list = getStateCollection('escolas');
        const _escolasOpts = []
        for(const [,item] of list.entries()){
            _escolasOpts.push({label:item.nome, value:item.nome})
        }
        setEscolasOptions(_escolasOpts);
    },[getStateCollection('escolas')])

    useEffect(()=>{
        const list = getStateCollection('cargos');
        const _cargosOpts = []
        for(const [,item] of list.entries()){
            _cargosOpts.push({label:item.nome, value:item.nome})
        }
        setCargosOptions(_cargosOpts);
    },[getStateCollection('cargos')])

    useEffect(() => {
        const list = getStateCollection('professores');
        const _map = {}
        if(list){
            for(const [, item] of list.entries()){
                _map[item.cpf] = item;
            }
        }
        setProfessoresMap(_map);
    }, [getStateCollection('professores')]);

    const MaskCPF = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/
    const watchCPF = watch('cpf');
    useEffect(()=>{
        if(MaskCPF.test(watchCPF)){
            const prof = professoresMap[watchCPF];
            if(prof){
                setValue('nome', prof.nome);
                setValue('tel_celular', prof.telefone);
                setValue('email', prof.email);
                setValue('matricula', prof.matricula);
                setValue('escola_atual', {label:prof.escola, value:prof.escola});
                setValue('cargo_atual', {label:prof.cargo, value:prof.cargo});
            }else{
                setValue('nome', "");
                setValue('tel_celular',"");
                setValue('email',"");
                setValue('matricula',"");
                setValue('escola_atual',null);
                setValue('cargo_atual',null);
            }
        }
    },[watchCPF])

    const goNextStep = async ()=>{
        setLoader({show: true,msg:t('verificando')})
        if(!servico._module_options.multiplo){
            const tabs = await moduleControl.buscarDocumentos(servico._id,{
                cpf:watchCPF
            });
            if(tabs && !tabs.error && tabs.length > 0){
                const tab = tabs[0];
                const pop = await Swal.fire({
                    title: t('info_cpf_cadastrado_vaga')+' N°: '+tab.uuid,
                    text: t('info_enviar_email_cancelar'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: t('sim'),
                    cancelButtonText: t('cancelar')
                })
                if(pop.isConfirmed){
                    if(tab.email){
                        await moduleControl.enviarEmailCancelamento(servico._id, tab.vaga, watchCPF,{
                            to:tab.email,
                            subject:t('info_cancelar_inscricao',{processo:servico._module_options.codigo, vaga: tab.vaga}),
                            body:'#link'
                        });
                        notify('info', t('cadastro'), t('info_email_enviado'))
                    }else{
                        notify('danger', t('cadastro'), t('info_nenhum_email_cadastrado'))
                    }
                    setLoader({show:false,msg:''})
                }else{
                    setLoader({show:false,msg:''})
                }
            }else{
                setLoader({show:false,msg:''})
                await nextStep()
            }
        }else{
            setLoader({show:false,msg:''})
            await nextStep()
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <fieldset>
                        <legend className="font-weight-semibold"><i className="icon-user mr-2"></i> {t('informacoes')}</legend>
                        <div className="form-group mb-1">
                            <label>{t('cpf')}</label>
                            <RHFCpf name="cpf"/>
                        </div>
                        <div className="form-group mb-1">
                            <label>{t('nome')}</label>
                            <input type="text" className="form-control form-control-sm" placeholder="" {...register("nome")} />
                        </div>
                        <div className="form-group mb-1">
                            <label>{t('dtnasc')}</label>
                            <RHFInputMask className="form-control form-control-sm" name="dtnasc" control={control} mask="99/99/9999"/>
                        </div>
                        <div className="form-group mb-1">
                            <div><label>{t('genero')}</label></div>
                            <select className="form-control form-control-sm" {...register("genero")}>
                                <option value=""></option>
                                <option value="M">{t('masculino')}</option>
                                <option value="F">{t('feminino')}</option>
                                <option value="OU">{t('outro')}</option>
                            </select>
                        </div>
                        <div className="form-group mb-1">
                            <label>{t('email')}</label>
                            <RHFEmail name="email"/>
                        </div>
                        <div className="form-group mb-1">
                            <label>{t('tel_celular')}</label>
                            <RHFInputMask className="form-control form-control-sm" name="tel_celular" control={control} mask="(99) 99999-9999"/>
                        </div>
                    </fieldset>
                </div>
                <div className="col-md-6">
                    <fieldset>
                        <legend className="font-weight-semibold"><i className="icon-office mr-2"></i> {t('matricula')}</legend>
                        <div className="form-group mb-1">
                            <label>{t('matricula')}</label>
                            <input type="text" className="form-control form-control-sm" placeholder="" {...register("matricula")} />
                        </div>
                        <div className="form-group mb-1">
                            <label>{t('escola_atual')}</label>
                            <RHFSelect
                                name="escola_atual"
                                placeholder={t('selecione_o', {item: t('escola_atual')})}
                                control={control}
                                options={escolasOptions}
                            />
                        </div>
                        <div className="form-group mb-1">
                            <label>{t('cargo_atual')}</label>
                            <RHFSelect
                                name="cargo_atual"
                                placeholder={t('selecione_o', {item: t('cargo_atual')})}
                                control={control}
                                options={cargosOptions}
                            />
                        </div>
                    </fieldset>
                </div>
            </div>
            <div className="row pt-2 pb-0">
                <div className="col-md-12 text-center">
                    {(errors['cpf']) ? <p className="text-danger">{errors['cpf'].message}</p> : ''}
                    {(errors['email']) ? <p className="text-danger">{errors['email'].message}</p> : ''}
                </div>
            </div>
            <Divider />
            <div className="row">
                <div className="col-md-12 text-right">
                    <div className="btn-group">
                        <button onClick={() => previousStep()} type="button" className="btn btn-light"><i className="icon-arrow-left5 mr-2"></i> {t('anterior')}</button>
                        {(!errors['cpf'] && !errors['email']) ? <button onClick={() => goNextStep()} type="button" className="btn btn-success">{t('proximo')} <i className="icon-arrow-right5 ml-2"></i></button> : ""}
                    </div>
                    <div className="btn-group ml-2">
                        <Button variant="light" onClick={close}>{t('fechar')}</Button>
                    </div>
                </div>
            </div>
        </>
    );
};
const Step3 = ({documentos, close}) => {
    const {t} = useTranslation();
    const {previousStep, nextStep} = useWizard();

    return (
        <>
            <div className="d-flex flex-wrap">
                {(documentos) ? Object.keys(documentos).map((key) => {
                    const documento = documentos[key]
                    return (
                        <RHFInputDocumento key={key} name={"documento." + key} label={key} documento={documento} accept={{
                            'application/pdf': ['.pdf'],
                            'image/jpeg': ['.jpg', '.jpeg'],
                            'image/png': ['.png']
                        }} />
                    )
                }) : ''}
            </div>
            <Divider />
            <div className="row">
                <div className="col-md-12 text-right">
                    <div className="btn-group">
                        <button onClick={() => previousStep()} type="button" className="btn btn-light"><i className="icon-arrow-left5 mr-2"></i> {t('anterior')}</button>
                    </div>
                    <div className="btn-group ml-2">
                        <Button variant="light" onClick={close}>{t('fechar')}</Button>
                    </div>
                </div>
            </div>
        </>
    );
};
const Step4 = ({vaga, servico, registros, cadastro, close}) => {
    const {t} = useTranslation();

    const comprovanteRef = useRef();

    const reactToPrintFn = useReactToPrint({ contentRef :comprovanteRef });

    const salvarPDF = async ()=>{
        const canvas = await html2canvas(comprovanteRef.current);
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save("comprovante.pdf");
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header bg-transparent header-elements-inline py-0">
                            <h6 className="card-title py-3">{t('comprovante')}</h6>
                            <div className="header-elements">
                                <button type="button" onClick={(e) => salvarPDF(e)} className="btn btn-light btn-sm">
                                    <i className="icon-file-check mr-2"></i> {t('salvar')}
                                </button>
                                <button type="button" onClick={reactToPrintFn} className="btn btn-light btn-sm ml-3">
                                    <i className="icon-printer mr-2"></i> {t('imprimir')}
                                </button>
                            </div>
                        </div>
                        <ComprovanteFase1 key='comp' mail={true} ref={comprovanteRef} servico={servico} vaga={vaga} registros={registros} cadastro={cadastro}/>
                    </div>
                </div>
            </div>
            <Divider />
            <div className="row">
                <div className="col-md-12 text-right">
                    <div className="btn-group ml-2">
                        <Button variant="light" onClick={close}>{t('fechar')}</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default function ModalAbrirProcessoFase1(props){
    const {t} = useTranslation();

    const [loader, setLoader] = useState({show:false, msg:''})
    const [showModal, setShowModal] = useState(false);
    const [servico, setServico] = useState({});
    const [vagas, setVagas] = useState([]);

    const [vaga, setVaga] = useState(null);
    const [documentos, setDocumentos] = useState(null);
    const [registros, setRegistros] = useState([]);
    const [cadastro, setCadastro] = useState({});

    const methods = useForm();
    const {reset, getValues, watch, formState: { errors }} = methods;

    const watchCPF = watch("cpf");
    const watchVaga = watch("vaga");

    useEffect(()=>{
        setShowModal(props.show);
        setServico(props.servico);
        if(props.servico && props.servico._module_vagas){
            const list = []
            for(const codigo of Object.keys(props.servico._module_vagas)){
                let descricao = props.servico._module_vagas[codigo].descricao;
                if(!descricao || descricao.trim() === ''){
                    descricao = codigo;
                }
                list.push({value:codigo, name:descricao})
            }
            setVagas(list);
        }
    },[props.show, props.servico])

    const onEntered = ()=>{
        if(props.onOpen) props.onOpen(servico);
    }
    const close = ()=>{
        if(props.close) props.close(servico);
    }
    const onExited = ()=>{
        reset();
        setVaga(null);
        setDocumentos(null);
        if(props.onClose) props.onClose(servico);
    }

    useEffect(()=>{
        if(props.servico && props.servico._module_vagas && props.servico._module_vagas[watchVaga]){
            const item = props.servico._module_vagas[watchVaga];
            setVaga(item);
        }
        if(props.servico && props.servico._module_documentos && props.servico._module_documentos[watchVaga]){
            const docs = props.servico._module_documentos[watchVaga];
            setDocumentos(docs);
        }
    },[watchVaga])

    const finalizar = async (e)=>{
        setLoader({show:true, msg:t('cadastrando')})

        const data = getValues();

        const fields = {...data}

        if(data.cargo_atual) fields.cargo_atual = data.cargo_atual.value;
        if(data.escola_atual) fields.escola_atual = data.escola_atual.value;

        if(props.servico._module_documentos && props.servico._module_documentos[watchVaga]){
            const docs = props.servico._module_documentos[watchVaga];
            for(const idDoc of Object.keys(docs)){
                const doc = docs[idDoc];
                if(doc.validar && (!fields.documento || !fields.documento[idDoc])){
                    setLoader({show:false, msg:''})
                    notify('warning', t('documento'), t('error_faltando_documento_requerido',{id:idDoc}))
                    return;
                }
            }
        }

        const files = []
        const tabs = []
        if(fields.documento){
            for(const id of Object.keys(fields.documento)){
                if(fields.documento[id] && fields.documento[id].length > 0){
                    const filename = fields.documento[id][0].name;
                    const tab = {...fields};
                    tab.tipo_documento = id;
                    tab._filename = filename;
                    tab.valid = false;
                    delete tab.documento;
                    tabs.push(tab);
                    files.push(fields.documento[id][0])
                }
            }
        }
        //Caso não tenha nenhum documento
        if(tabs.length === 0){
            const tab = {...fields};
            tab.valid = false;
            delete tab.documento;
            tabs.push(tab)
        }
        let chave = {
            vaga:fields.vaga,
            cpf:fields.cpf
        }
        if(servico._module_options.multiplo){
            chave = {};
        }

        const uuid = fields.vaga;
        const rs = await moduleControl.salvarDocumentos(servico._id, chave, tabs, files, uuid);
        if(rs.error){
            notify('warning', t('processo'), t('error_inscricao'))
        }else{
            setRegistros(rs.registros);
            const insertedFields = {...rs.registros[0]};
            setCadastro(insertedFields)
            await goToStep(stepCount-1)
        }
        setLoader({show:false})
    }

    const [activeStep, setActiveStep] = useState(false)
    const [stepCount, setStepCount] = useState(false)

    const wizardRef = useRef(null);
    const listenerStep = (props)=>{
        setActiveStep(props.activeStep)
        setStepCount(props.stepCount);
    }

    const goToStep = async (index)=>{
        await wizardRef.current.goToStep(index)
    }

    return (
        <Modal
            show={showModal}
            onExited={onExited}
            onEntered={onEntered}
            animation={true}
            dialogClassName="modal-full"
        >
            <Loader show={loader.show} msg={loader.msg} type="modal" />
            <FormProvider {...methods} >
                <Modal.Header className="bg-light p-2 pl-3">
                    <h6 className="font-weight-semibold m-0">
                        {servico.nome}
                        <small className="d-block text-muted">{(servico.descricao) ? servico.descricao : ''}</small>
                    </h6>
                </Modal.Header>
                <Modal.Body className="pt-1">
                    <div className="row">
                        <div className="col-md-12">
                            <WizardForm listenerStep={listenerStep} ref={wizardRef} steps={
                                [
                                    <Step1 action={t('selecione')} name="vaga" vagas={vagas} vaga={vaga} servico={servico} close={close} setLoader={setLoader} />,
                                    <Step2 action={t('info_preencha_cadastro')} name="cadastro" servico={servico} close={close} setLoader={setLoader}  />,
                                    <Step3 action={t('info_envie_documentos')} name="documentos" documentos={documentos} close={close} setLoader={setLoader}  />,
                                    <Step4 action={t('comprovante')} name="comprovante" registros={registros} cadastro={cadastro} vaga={vaga} servico={servico} close={close} setLoader={setLoader}  />
                                ]
                            } />
                        </div>
                    </div>
                    {(vaga && !errors['cpf'] && activeStep === 2) ?
                        <>
                            <Divider />
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <Button className="btn-lg" variant="primary" onClick={(e)=>{finalizar(e).catch(err=>{console.error(err)})}}>{t('cadastrar')}</Button>
                                </div>
                            </div>
                        </>
                        : ''}
                </Modal.Body>
            </FormProvider>
        </Modal>
    )
}