import {useContext, useEffect, useRef, useState} from "react";
import {Button, Image, Modal, Nav, Navbar} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import $ from 'jquery'

import AuthContext from "../context/AuthContext";
import ConfigContext from "../context/ConfigContext";
import Loader from "./Loader";
import Notify, {notify} from "./Notify";
import ControlContext from "../context/ControlContext";

export default function Menu(){
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {version} = useContext(ConfigContext);
    const {user, security, signOut, signIn, sleep} = useContext(AuthContext)
    const {leftSidebar, rightSidebar} = useContext(ControlContext);

    const $client = useRef('client')
    const $login = useRef('login')
    const $password = useRef('password')

    const [showLoginModal, setShowLoginModal] = useState(false);
    const handleLoginClose = () => setShowLoginModal(false);
    const handleLoginEntered = ()=> {$login.current.focus()}

    const [showUsuarioModal, setShowUsuarioModal] = useState(false);
    const handleUsuarioClose = () => setShowUsuarioModal(false);
    const handleUsuarioEntered = ()=> {}

    const [loaderLogin, setLoaderLogin] = useState({show:false, msg:''})
    const [loaderUsuario, setLoaderUsuario] = useState({show:false, msg:''})

    const openLoginModal = (event)=>{
        event.preventDefault();
        setShowLoginModal(true);
    }

    const login = async (event)=>{
        event.preventDefault();
        setLoaderLogin({show:true, msg:t('autenticando')})
        await sleep(1000);
        const data = {
            client: $client.current.value,
            login: $login.current.value,
            password: $password.current.value,
        }
        if(!data.client || data.client.trim() === ''){
            data.client = 'inbox';
        }
        const rs = await signIn(data).catch(err=>{
            console.log('error',err);
            notify('danger', t('autenticando'), t('error_server'))
        });
        if(rs && rs.error){
            notify('warning', t('autenticando'), rs.error)
        }
        setLoaderLogin({show:false, msg:''})
    }

    const abrirUsuario = async (event)=>{
        event.preventDefault()
        setShowUsuarioModal(true);
    }

    const logout = async (event)=>{
        setLoaderUsuario({show:true, msg:t('efetuando_logout')})
        event.preventDefault()
        await signOut(user);
        setShowUsuarioModal(false);
        setLoaderUsuario({show:false, msg:''})
        navigate('/')
    }

    useEffect(()=>{
        if(user){
            setShowLoginModal(false);
        }
    },[user])

    const sidebarMobileLeftToggle = (e)=>{
        e.preventDefault();
        $('body').toggleClass('sidebar-mobile-secondary');
    }
    const sidebarMobileRightToggle = (e)=>{
        e.preventDefault();
        $('body').toggleClass('sidebar-mobile-right');
    }

    return (
        <>
            <Navbar expand="sm" style={{padding: 0}} className="navbar-sm">
                <div style={{padding: "8px 15px 5px 15px"}}>
                    <img src="/icons/icon.png" alt=""/>
                </div>
                <div className="d-md-none">
                    {(leftSidebar) ? <>
                        <button type="button" className="navbar-toggler sidebar-mobile-main-toggle collapsed"
                                onClick={(e) => sidebarMobileLeftToggle(e)}>
                            <i className="icon-transmission"></i>
                        </button>
                    </> : <></>}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" children={<i className="icon-menu7"></i>}/>
                    {rightSidebar ? <>
                        <button type="button" className="navbar-toggler sidebar-mobile-right-toggle"
                                onClick={(e) => sidebarMobileRightToggle(e)}>
                            <i className="icon-transmission"></i>
                        </button>
                    </> : <></>}
                </div>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        <ul className="navbar-nav">
                            <li className="nav-item"><Link className="navbar-nav-link" to="/home"><i className="icon-stack2 mr-2"></i>{t('processos')}</Link></li>
                            {(!user || !security || !security['cadastro']) ? '' : <li className="nav-item"><Link className="navbar-nav-link" to="/cadastro"><i className="icon-stack2 mr-2"></i> {t('cadastro')}</Link></li>}
                            {(!user || !security || !security['validar']) ? '' : <li className="nav-item"><Link className="navbar-nav-link" to="/validar"><i className="icon-file-check mr-2"></i> {t('validacao')}</Link></li>}
                            {(!user || !security || !security['inscritos']) ? '' : <li className="nav-item"><Link className="navbar-nav-link" to="/inscritos"><i className="icon-users mr-2"></i> {t('inscritos')}</Link></li>}
                            {(!user || !security || !security['classificar']) ? '' : <li className="nav-item"><Link className="navbar-nav-link" to="/classificar"><i className="icon-loop4 mr-2"></i> {t('classificar')}</Link></li>}
                        </ul>
                    </Nav>
                    <Nav className="ml-sm-auto">
                        <ul className="navbar-nav">
                            {(user) ? <>
                                    <li className="nav-item">
                                        <Link className="navbar-nav-link" to="#" onClick={(event) => {
                                            abrirUsuario(event)
                                        }}>
                                            <i className="icon-user text-primary mr-2" style={{paddingBottom: "3px"}}></i>{user.user.nome}
                                        </Link>
                                    </li>
                                </>
                                :
                                <li className="nav-item">
                                    <Link className="navbar-nav-link" to="#" onClick={(event) => {
                                        openLoginModal(event)
                                    }}>
                                        <i className="icon-user mr-2" style={{paddingBottom: "3px"}}></i>{t('entrar')}
                                    </Link>
                                </li>
                            }
                        </ul>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Modal
                show={showLoginModal}
                onEntered={handleLoginEntered}
                animation={true}
                dialogClassName="modal-xs"
            >
                <Loader show={loaderLogin.show} msg={loaderLogin.msg} type="modal"/>
                <Modal.Body>
                    <div className="d-flex justify-content-center align-items-center" style={{width: '100%'}}>
                        <form className="login-form" onSubmit={login}>
                            <div className="card mb-0 border-0">
                                <div className="card-body">
                                    <div className="text-center mb-3">
                                        <Image src="/icons/logo.png" width={113} height={125} alt=""/>
                                    </div>
                                    <div className="form-group form-group-feedback form-group-feedback-left">
                                        <input type="text" ref={$login} className="form-control" placeholder={t('username')} />
                                        <div className="form-control-feedback">
                                            <i className="icon-user text-muted"></i>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-feedback form-group-feedback-left">
                                        <input type="password" ref={$password} className="form-control" placeholder={t('password')} />
                                        <div className="form-control-feedback">
                                            <i className="icon-lock2 text-muted"></i>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-feedback form-group-feedback-left">
                                        <input type="text" ref={$client} className="form-control" placeholder={t('cliente')} />
                                        <div className="form-control-feedback">
                                            <i className="icon-office text-muted"></i>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary btn-block">{t('entrar')}</button>
                                    </div>
                                    <div className="text-center text-muted text-size-mini" id="version-login">{version}</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleLoginClose}>{t('fechar')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showUsuarioModal}
                onEntered={handleUsuarioEntered}
                animation={true}
                dialogClassName="modal-xs"
            >
                <Loader show={loaderUsuario.show} msg={loaderUsuario.msg} type="modal" />
                <Modal.Body>
                    <div className="d-flex justify-content-center align-items-center" style={{width: '100%'}}>
                        <form className="login-form" onSubmit={login}>
                            <div className="card mb-0 border-0">
                                <div className="card-body">
                                    <div className="text-center mb-3"></div>
                                    <div className="text-center mb-3">
                                        {
                                            (user && user.user) ?
                                                <>
                                                    <div>{(user.user.nome) ? user.user.nome : ''}</div>
                                                    <div>{(user.user.email) ? user.user.email : ''}</div>
                                                    <div className="text-muted">{(user.user.cliente) ? user.user.cliente : ''}</div>
                                                </>
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={logout}>{t('sair')}</Button>
                    <Button variant="light" onClick={handleUsuarioClose}>{t('fechar')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}