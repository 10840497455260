import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useWizard} from "react-use-wizard";
import {Divider} from "rsuite";


const WizFooter = forwardRef(({steps, listenerStep}, ref) => {

    const { previousStep, nextStep, activeStep, isFirstStep, isLastStep, stepCount, goToStep } = useWizard();

    useImperativeHandle(ref, () => ({ nextStep, previousStep, goToStep}), [])
    useEffect(()=>{
        if(listenerStep) listenerStep({activeStep, stepCount, isFirstStep, isLastStep});
    },[activeStep])

    return (
        <>
            <div></div>
        </>
    )

})

export default WizFooter