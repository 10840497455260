import React, {useEffect, useState, useContext, forwardRef, useRef, useImperativeHandle} from "react";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useForm} from "react-hook-form";
import {notify} from "./Notify";
import ServicoContext from "../context/ServicoContext";
import Loader from "./Loader";
import {orderByKey} from "../control/Util"
import Swal from "sweetalert2";
import RHFCheckbox from "./forms/RHFCheckbox";

const VagaItem = forwardRef((props, ref)=>{
    const {t} = useTranslation();

    useImperativeHandle(
        ref,
        () => ({}),
    )

    const editar = (event)=>{
        event.preventDefault();
        if(props.editar) props.editar(props.data);
    }

    const remover = async (event)=>{
        event.preventDefault();
        const pop = await Swal.fire({
            title: t('info_voce_tem_certeza'),
            text: t('info_sera_removido',{item: props.data.codigo}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('sim'),
            cancelButtonText: t('cancelar')
        })
        if(pop.isConfirmed){
            if(props.remover) props.remover(props.data);
        }
    }

    const [selected,setSelected] = useState(false)

    useEffect(()=>{
        if(props.edit && props.edit.codigo === props.data.codigo){
            setSelected(true)
        }else{
            setSelected(false)
        }
    },[props.edit])

    if(!props.data) return <></>

    return (
        <>
            <div onClick={(event)=>editar(event)} className="list-group-item list-group-item-action cursor-pointer pl-1 pr-1" style={(selected) ? {backgroundColor:"#d5d5d5"} : {}}>
                <div className="w-50" >
                    <span className="font-weight-semibold"><i className="icon-arrow-right22 mr-1"></i>{props.data.codigo}</span>
                </div>
                <div className="w-50 text-right">
                    <span className="badge badge-light ml-auto mr-2">{props.data.quantidade}</span>
                    <a href="#" onClick={(event)=>remover(event)} className="badge badge-danger p-1"><i className="icon-trash position-static"></i></a>
                </div>
            </div>
        </>
    )
})
const DocumentoItem = forwardRef((props, ref)=>{
    const {t} = useTranslation();

    useImperativeHandle(
        ref,
        () => ({}),
    )

    const remover = async (event)=>{
        event.preventDefault();
        const pop = await Swal.fire({
            title: t('info_voce_tem_certeza'),
            text: t('info_sera_removido',{item: props.data.codigo}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('sim'),
            cancelButtonText: t('cancelar')
        })
        if(pop.isConfirmed){
            if(props.remover) props.remover(props.data);
        }
    }

    if(!props.data) return <></>

    return (
        <>
            <div className="list-group-item list-group-item-action pl-1 pr-1">
                <div className="w-50">
                    <span className="font-weight-semibold"><i className="icon-arrow-right22 mr-1"></i>{props.data.documento}</span>
                </div>
                <div className="w-50 text-right">
                    <span className="badge badge-light mr-1">{props.data.pontos}</span>
                    {(props.data.validar) ?
                        <span className="badge badge-light p-1 mr-1"><i className="icon-file-check position-static"></i></span>
                        :<span className="badge badge-light p-1 mr-1"><i className="icon-file-empty2 position-static"></i></span>}
                    <a href="#" onClick={(event)=>remover(event)} className="badge badge-danger p-1"><i className="icon-trash position-static"></i></a>
                </div>
            </div>
        </>
    )
})

export default function ModalEditarProcesso(props){
    const {t} = useTranslation();
    const {editar:editarServico} = useContext(ServicoContext)

    const [loader, setLoader] = useState({show:false, msg:''})
    const [showModal, setShowModal] = useState(false);
    const [servico, setServico] = useState(null);
    const [vagas, setVagas] = useState([])
    const [documentos, setDocumentos] = useState([])
    const [edit, setEdit] = useState(null);

    const {register, handleSubmit, reset, setFocus, setValue, getValues } = useForm();
    const {register:registerDOC, control:controlDOC, handleSubmit:handleSubmitDOC, reset:resetDOC } = useForm();

    const novaVaga = async ()=>{
        reset();
        setEdit(null)
    }

    const vagaRefs = useRef({});
    useEffect(()=>{
        setShowModal(props.show);
        setServico(props.servico);

        if(props.servico && props.servico._module_vagas){

            const editarVaga = (item)=>{
                resetDOC();
                setEdit(item);
                setValue('codigo', item.codigo)
                setValue('descricao', item.descricao)
                setValue('bairro', item.bairro)
                setValue('cidade', item.cidade)
                setValue('uf', item.uf)
                setValue('quantidade', item.quantidade)
            }

            const removerVaga = async (item)=>{
                setLoader({show:true, msg: t('removendo')})
                if(edit && edit.codigo === item.codigo){
                    await novaVaga();
                }
                if(props.servico._module_vagas[item.codigo]){
                    const $setInbox = {}
                    const moduleVagas = {...props.servico._module_vagas}
                    delete moduleVagas[item.codigo]
                    $setInbox._module_vagas = moduleVagas

                    if(props.servico._module_documentos && props.servico._module_documentos[item.codigo]){
                        const moduleDocs = {...props.servico._module_documentos}
                        delete moduleDocs[item.codigo]
                        $setInbox._module_documentos = moduleDocs
                    }

                    try{
                        const rs = await editarServico(props.servico._id, {inbox:$setInbox})
                        if(rs.error) {
                            notify('danger', t('documentos'), t('error_problema_remover_vaga'))
                        }
                        setLoader({show:false, msg:''})
                    }catch (err){
                        console.error(err);
                        notify('danger', t('documentos'), t('error_problema_remover_vaga'))
                        setLoader({show:false, msg:''})
                    }
                }
            }

            const list = []
            const sortedList = orderByKey(Object.values(props.servico._module_vagas),'codigo');
            for(const [i,vaga] of sortedList.entries()){
                list.push(<VagaItem
                    key={vaga.codigo}
                    edit={edit}
                    editar={editarVaga}
                    remover={removerVaga}
                    data={vaga}
                    ref={el => vagaRefs.current[vaga.codigo] = el}/>)
            }
            setVagas(list)
        }

        if(props.servico && props.servico._module_documentos && edit){
            const removerDocumento = async (item)=>{
                setLoader({show:true, msg: t('removendo')})
                if(props.servico._module_documentos[edit.codigo]){
                    const moduleDocs = {...props.servico._module_documentos}
                    delete moduleDocs[edit.codigo][item.documento];
                    try{
                        const rs = await editarServico(props.servico._id, {inbox:{'_module_documentos':moduleDocs}})
                        if(rs.error) {
                            notify('danger', t('documentos'), t('error_problema_remover_documento'))
                        }
                        setLoader({show:false, msg:''})
                    }catch (err){
                        console.error(err);
                        notify('danger', t('documentos'), t('error_problema_remover_documento'))
                        setLoader({show:false, msg:''})
                    }
                }
            }

            const list = []
            if(props.servico._module_documentos[edit.codigo]){
                const sortedList = orderByKey(Object.values(props.servico._module_documentos[edit.codigo]),'documento');
                for(const [i, item] of sortedList.entries()){
                    list.push(<DocumentoItem
                        key={item.documento}
                        remover={removerDocumento}
                        data={item}
                        ref={el => vagaRefs.current[item.documento] = el}/>)
                }
            }
            setDocumentos(list)
        }

    },[props.show, props.servico, edit])

    const onEntered = ()=>{
        setFocus("codigo", { shouldSelect: true })
        if(props.onOpen) props.onOpen(servico);
    }
    const close = ()=>{
        if(props.close) props.close(servico);
    }
    const onExited = ()=>{
        reset();
        resetDOC();
        setEdit(null);
        if(props.onClose) props.onClose(servico);
    }

    const onSubmitVaga = async (data, e)=>{
        const fields = {...data};

        if(!fields.codigo || fields.codigo.trim() === ''){
            notify('warning', t('vagas'), t('info_campo_vazio',{campo:t('codigo_vaga')}))
            return;
        }

        if(!fields.quantidade) fields.quantidade = 0;

        let vagas = {}
        if(servico._module_vagas) vagas = {...servico._module_vagas}

        vagas[fields.codigo] = {
            codigo:fields.codigo,
            descricao:fields.descricao,
            bairro:fields.bairro,
            cidade:fields.cidade,
            uf:fields.uf,
            quantidade:fields.quantidade
        }

        setLoader({show:true, msg: t('salvando')})
        try{
            const rs = await editarServico(servico._id, {inbox:{'_module_vagas':vagas}})
            if(rs.error) {
                notify('danger', t('processo'), t('error_problema_salvar_processo'))
            }
            setEdit(vagas[fields.codigo]);
            setLoader({show:false, msg:''})
        }catch (err){
            console.error(err);
            notify('danger', t('processo'), t('error_problema_salvar_vaga'))
            setLoader({show:false, msg:''})
        }
    }

    const onSubmitDocumento = async (data, e)=>{
        const fields = {...data};
        if(!fields.documento || fields.documento.trim() === ''){
            notify('warning', t('documentos'), t('info_campo_vazio',{campo:t('documento')}))
            return;
        }

        const codigo = getValues('codigo');
        if(!codigo || codigo.trim() === ''){
            notify('warning', t('documentos'), t('info_campo_vazio',{campo:t('codigo_vaga')}))
            return;
        }

        if(!fields.pontos) fields.pontos = 0;

        let moduleDocs = {}
        if(servico._module_documentos) moduleDocs = {...servico._module_documentos}
        if(!moduleDocs[codigo]) moduleDocs[codigo] = {}

        moduleDocs[codigo][fields.documento] = {
            documento: fields.documento,
            pontos: fields.pontos,
            validar: fields.validar
        }

        setLoader({show:true, msg: t('salvando')})
        try{
            const rs = await editarServico(servico._id, {inbox:{'_module_documentos':moduleDocs}})
            if(rs.error) {
                notify('danger', t('documentos'), t('error_problema_adicionar_documento'))
            }else{
                resetDOC()
            }
            setLoader({show:false, msg:''})
        }catch (err){
            console.error(err);
            notify('danger', t('documentos'), t('error_problema_adicionar_documento'))
            resetDOC()
            setLoader({show:false, msg:''})
        }
    }


    if(!servico){
        return <></>
    }

    return (
        <Modal
            show={showModal}
            onExited={onExited}
            onEntered={onEntered}
            animation={true}
            dialogClassName="modal-full"
        >
            <Loader show={loader.show} msg={loader.msg} type="modal" />
            <Modal.Header>
                <h6 className="font-weight-semibold">
                    <i className="icon-pencil mr-2" />
                    {servico.nome}
                </h6>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header bg-light p-2">
                                <span className="card-title font-weight-semibold">{t('cargos')}</span>
                            </div>
                            <ul className="list-group border-x-0 rounded-0 pre-scrollable" style={{maxHeight:'530px',minHeight:'530px'}}>
                                {(vagas.length > 0) ? vagas : <span className="p-2">{t('info_nenhuma_vaga')}</span>}
                            </ul>
                        </div>
                    </div>
                    <div className={(edit) ? "col-md-4" : "col-md-8"}>
                        <div className="card mb-2">
                            <div className="card-header bg-light p-2">
                                <span className="card-title font-weight-semibold">{t('cadastro')}</span>
                            </div>
                            <div className="card-body">
                                <form key="vaga" onSubmit={handleSubmit(onSubmitVaga)}>
                                    <div className="form-group mb-2">
                                        <label>{t('codigo_cargo')}</label>
                                        <input className="form-control form-control-sm" disabled={!!edit}  type="text" placeholder="" {...register("codigo")} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>{t('descricao')}</label>
                                        <input className="form-control form-control-sm" type="text" placeholder="" {...register("descricao")} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>{t('bairro')}</label>
                                        <input className="form-control form-control-sm" type="text" placeholder="" {...register("bairro")} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>{t('cidade')}</label>
                                        <input className="form-control form-control-sm" type="text" placeholder="" {...register("cidade")} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>{t('uf')}</label>
                                        <input className="form-control form-control-sm" type="text" placeholder="" {...register("uf")} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>{t('quantidade_vagas')}</label>
                                        <input className="form-control form-control-sm" type="number" placeholder="" {...register("quantidade", {value:0,valueAsNumber:true})} />
                                    </div>
                                    <div className="form-group mb-2">
                                        {(edit) ?
                                            (<>
                                                <Button type="submit"  variant="success" className="mr-1">{t('salvar')}</Button>
                                                <Button onClick={(event)=>{novaVaga(event)}} variant="warning">{t('novo')}</Button>
                                            </>) :
                                            (<Button type="submit"  variant="primary">{t('adicionar')}</Button>)
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4" style={(edit) ? {display:'block'}:{display:'none'}}>
                        <div className="card mb-2">
                            <div className="card-header bg-light p-2">
                                <span className="card-title font-weight-semibold">{t('documentos')}</span>
                            </div>
                            <div className="card-body">
                                <form key='documento' onSubmit={handleSubmitDOC(onSubmitDocumento)}>
                                    <div className="form-group mb-2">
                                        <label>{t('documento')}</label>
                                        <input className="form-control form-control-sm" type="text" placeholder="" {...registerDOC("documento")} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>{t('pontos')}</label>
                                        <input className="form-control form-control-sm" type="number" placeholder="" {...registerDOC("pontos", {value:0,valueAsNumber:true})} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>{t('documento_comprobatorio')}</label>
                                        <RHFCheckbox control={controlDOC} name="validar" />
                                    </div>
                                    <div className="form-group mb-2">
                                        <Button type="submit"  variant="primary">{t('adicionar')}</Button>
                                    </div>
                                </form>
                            </div>
                            <ul className="list-group border-x-0 rounded-0 pre-scrollable" style={{maxHeight:'244px',minHeight:'244px'}} >
                                {(documentos.length > 0) ? documentos : <span className="p-2">{t('info_nenhuma_documento')}</span>}
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={close}>{t('fechar')}</Button>
            </Modal.Footer>

        </Modal>
    )
}