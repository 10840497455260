import {useTranslation} from "react-i18next";
import React, {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import TableCrud from "./TableCrud";
import ControlContext from "../../context/ControlContext";

import {Tooltip} from "primereact/tooltip";
import {Toast} from "primereact/toast";

import ServicoContext from "../../context/ServicoContext";
import Swal from "sweetalert2";
import {startLoadingAction, stopLoadingAction} from "./Util";
import ModalImportarXLSX from "./modal/ModalImportarXLSX";
import ModalClearDuplicates from "./modal/ModalClearDuplicates";
import ModalNew from "./modal/ModalNew";
import Inputmask from "inputmask";

const Crud = forwardRef((props, ref) => {

    const {
        id,
        name = "list",
        data=[],
        persistTable = false,
        footer,
        focus = 'nome',
        configuration,
        noKeyEvents,
        initialSort=[],
        hide={}
    } = props;

    useImperativeHandle(
        ref,
        () => ({
            isEditMode(){
                return (tableRef && tableRef.current) ? tableRef.current.isEditMode() : false;
            },
            getSelectedTableData(){
                return (tableRef && tableRef.current) ? tableRef.current.getSelectedData() : [];
            },
            getTabulator(){
                return (tableRef && tableRef.current) ? tableRef.current.tabulator() : null;
            }
        }),
    )

    const {t} = useTranslation();

    const {listarCadastro, updateManyCadastro, removerCadastro, limparCadastro} = useContext(ServicoContext);

    const [configMap, setConfigMap] = useState({});
    const [tableData, setTableData] = useState([])
    const [tableColumns, setTableColumns] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editedCells, setEditedCells] = useState({})

    const toast = useRef(null);
    const tableRef = useRef();

    const refTooltip = useRef();
    const refBtnInserir = useRef();
    const refBtnImportar = useRef();
    const refBtnEditar = useRef();
    const refBtnEditarConfirmar = useRef();
    const refBtnEditarCancelar = useRef();
    const refBtnRemover = useRef();
    const refBtnClearDuplicates = useRef();
    const refBtnLimpar = useRef();

    const refBtnExportarXLSX = useRef();

    const refDivInserir = useRef();
    const refDivEditar = useRef();
    const refDivRemover = useRef();


    const handleKey = (event) => {
        //console.log(event);
        if (event.key === '+') {
            newItem();
        }else if (event.keyCode === 46) { //Delete Key
            remover().catch(err=>{
                console.error(err);
            })
        }
    };

    useEffect(() => {
        const init = async ()=>{
            if(configuration && configuration.table){
                await initSetup(configuration.table);
            }
        }
        init().catch(err=>{
            console.error(err);
        })
        if(!noKeyEvents){
            window.addEventListener('keydown', handleKey);
        }
        return () => {
            if(!noKeyEvents){
                window.removeEventListener('keydown', handleKey);
            }
        };
    }, []);


    useEffect(()=>{
        //console.log(editedCells);
    },[editedCells])

    const referenceEditor = (cell, onRendered, success, cancel, editorParams)=>{
        //cell - the cell component for the editable cell
        //onRendered - function to call when the editor has been rendered
        //success - function to call to pass the successfully updated value to Tabulator
        //cancel - function to call to abort the edit and return to a normal cell
        //editorParams - params object passed into the editorParams column definition property
        const row = cell.getRow();
        const rowData = row.getData();
        const columnId = cell.getField();
        const cellValue = cell.getValue();
        const fieldItem = editorParams.fieldItem

        const editor = document.createElement("select");
        editor.style.width = "100%";


        let values = [];
        if(editorParams.refMap[columnId]){
            editorParams.refMap[columnId].map((entry)=>{
                values.push(entry)
            })
        }

        values.forEach(entry => {
            let optionElement = document.createElement("option");
            optionElement.value = entry[fieldItem._crud_label];
            optionElement.textContent = entry[fieldItem._crud_label];
            editor.appendChild(optionElement);
        });

        if (values.length === 0) {
            let placeholderOption = document.createElement("option");
            placeholderOption.value = "";
            placeholderOption.textContent = t('msg_nenhum_registro');
            placeholderOption.disabled = true;
            placeholderOption.selected = true;
            editor.appendChild(placeholderOption);
        }

        editor.value = (cellValue) ? cellValue : "";

        onRendered(()=>{
            editor.focus();
            //editor.style.css = "100%";
        });

        editor.addEventListener("change", (e)=>{
            success(e.target.value);
        });

        return editor;
    };

    const cpfEditor = function(cell, onRendered, success, cancel, editorParams){
        const input = document.createElement('input');
        input.type = 'text';
        input.value = cell.getValue() || '';

        Inputmask({mask:"999.999.999-99", clearMaskOnLostFocus:true, clearIncomplete: true}).mask(input);

        onRendered(() => {
            input.focus();
            input.style.width = '100%';
        });

        // Lógica de sucesso na edição
        input.addEventListener('blur', () => success(input.value));
        input.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
                success(input.value);
            } else if (e.key === 'Escape') {
                cancel();
            }
        });

        return input;
    };
    const phoneEditor = function(cell, onRendered, success, cancel, editorParams){
        const input = document.createElement('input');
        input.type = 'text';
        input.value = cell.getValue() || '';

        Inputmask({mask:"(99) 99999-9999", clearMaskOnLostFocus:true, clearIncomplete: true}).mask(input);

        onRendered(() => {
            input.focus();
            input.style.width = '100%';
        });

        // Lógica de sucesso na edição
        input.addEventListener('blur', () => success(input.value));
        input.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
                success(input.value);
            } else if (e.key === 'Escape') {
                cancel();
            }
        });

        return input;
    };

    const onCellEdited = (cell)=>{
        const columnId = cell.getField();
        const row = cell.getRow();
        const rowData = row.getData();
        const edited = {
            [rowData._id]:{
                [columnId]:cell.getValue()
            }
        }
        setEditedCells((prevState)=>{
            return {...prevState, [rowData._id]:{...prevState[rowData._id], ...edited[rowData._id]}}
        })
    }

    const initSetup = async (table)=>{
        if(!table) return;

        const refMap = {}
        for(const [,item] of table.entries()){
            if(item._type === 'reference'){
                //console.log(item.field, "Atualizando Dados");
                refMap[item.field] = await listarCadastro(item._crud, {}, {[item._crud_label]:1})
            }
        }

        const _configMap = {}
        for(const [,entry] of table.entries()){
            _configMap[entry.field] = entry;
        }
        setConfigMap(_configMap);

        const columns = [];

        for(const [,entry] of table.entries()){
            const tableColumn = {}
            for(const attr of Object.keys(entry)){
                if(attr.startsWith('_')) continue;
                tableColumn[attr] = entry[attr];
            }
            if(entry._type === "reference"){
                tableColumn.editor = referenceEditor;
                tableColumn.editorParams = {
                    refMap,
                    fieldItem:entry,
                    configMap:_configMap
                }
            }else if(entry._type === "cpf"){
                tableColumn.editor = cpfEditor;
            }else if(entry._type === "phone"){
                tableColumn.editor = phoneEditor;
            }
            columns.push(tableColumn);
        }

        setTableColumns(columns);
    }


    useEffect(()=>{
        setTableData(data);
    },[data])

    const exportar_xlsx = ()=>{
        tableRef.current.tabulator().download("xlsx", name+".xlsx", {});
    }

    const [newModalShow, setNewModalShow] = useState(false);
    const newItem = ()=>{
        setNewModalShow(true);
    }
    const newModalClose = ()=>{
        setNewModalShow(false)
    }

    const remover = async (event)=>{
        const btn = refBtnRemover.current
        startLoadingAction(btn);

        const selectedData = tableRef.current.getSelectedData();

        if(selectedData && selectedData.length > 0){
            const pop = await Swal.fire({
                title: t('info_voce_tem_certeza'),
                text: t('info_registros_removido', {count:selectedData.length}),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('sim'),
                cancelButtonText: t('cancelar')
            })
            if(pop.isConfirmed){
                const ids = []
                for(const [,item] of selectedData.entries()){
                    ids.push(item._id)
                }
                removerCadastro(id, ids)
                stopLoadingAction(btn);
            }else{
                stopLoadingAction(btn);
            }
        }else{
            stopLoadingAction(btn);
        }
    }

    const [clearModalShow, setClearModalShow] = useState(false);
    const clearDuplicates = ()=>{
        setClearModalShow(true);
    }
    const clearModalClose = ()=>{
        setClearModalShow(false)
    }

    const [dropzoneModalShow, setDropzoneModalShow] = useState(false);
    const importarXLSX = ()=>{
        setDropzoneModalShow(true);
    }
    const dropzoneModalClose = ()=>{
        setDropzoneModalShow(false)
    }

    useEffect(() => {
        if(editMode){
            refBtnEditar.current.classList.remove('btn-light')
            refBtnEditar.current.classList.add('btn-secondary');
            refBtnEditarConfirmar.current.style.display = "inline";
            refBtnEditarCancelar.current.style.display = "inline";

            refDivRemover.current.style.display = "none";
            refDivInserir.current.style.display = "none";
        }else{
            refBtnEditar.current.classList.add('btn-light')
            refBtnEditar.current.classList.remove('btn-secondary');
            refBtnEditarConfirmar.current.style.display = "none";
            refBtnEditarCancelar.current.style.display = "none";

            refDivRemover.current.style.display = "inline";
            refDivInserir.current.style.display = "inline";
        }
    }, [editMode]);

    const modoEditar = ()=>{
        setEditMode(true);
    }

    const modoEditarConfirmar = ()=>{

        if(Object.keys(editedCells).length === 0){
            return;
        }

        //console.log(editedCells);return;

        const btnConfirmar = refBtnEditarConfirmar.current
        startLoadingAction(btnConfirmar);

        const validateAll = async ()=>{
            const generalErrors = {}
            for(const _id of Object.keys(editedCells)){
                const item = editedCells[_id];
                const row = tableRef.current.tabulator().getRow(_id);

                for(const field of Object.keys(item)){
                    const conf = configMap[field];
                    const value = (item[field]) ? item[field]+"" : "";

                    if(conf.hasOwnProperty('_mandatory') && conf._mandatory && value.trim() === ''){
                        const cell = row.getCell(field);
                        const cellElement = cell.getElement();
                        cellElement.classList.add("tabulator-cell-error");

                        cellElement.setAttribute("data-pr-tooltip", t('info_campo_vazio',{campo:conf.title}));
                        cellElement.setAttribute("data-pr-position", "top");

                        generalErrors[field] = t('info_campo_vazio',{campo:conf.title})
                    }
                }
            }

            return generalErrors;
        }

        validateAll().then((generalErrors)=>{
            if(Object.keys(generalErrors).length > 0) {
                refTooltip.current.updateTargetEvents();
                toast.current.show({severity:'error', summary: t('salvando'), detail:t('error_update'), life: 2000});
                stopLoadingAction(btnConfirmar);
                return;
            }
            updateManyCadastro(id, editedCells).then(()=>{
                toast.current.show({severity:'info', summary: t('salvando'), detail:t('sucesso'), life: 2000});
                setEditMode(false);
                stopLoadingAction(btnConfirmar);
            }).catch(err=>{
                toast.current.show({severity:'error', summary: t('salvando'), detail:t('error_salvar'), life: 2000});
                stopLoadingAction(btnConfirmar);
                console.error(err);
            })
        }).catch(err=>{
            toast.current.show({severity:'error', summary: t('salvando'), detail:t('error_salvar'), life: 2000});
            stopLoadingAction(btnConfirmar);
            console.error(err);
        })

    }

    const modoEditarCancelar = ()=>{
        setEditMode(false);
        setEditedCells({});
        listarCadastro(id).catch(err=>{
            console.error(err);
        })
    }

    const limpar = async (event)=>{
        const btn = refBtnLimpar.current
        startLoadingAction(btn);
        const { value: collectionName } = await Swal.fire({
            title: t('msg_limpar_registros'),
            html:t('info_remover_informe_palavra',{value:"<b>"+id+"</b>"}),
            input: 'text',
            inputValue: '',
            inputPlaceholder: t('info_informe_palavra',{value:""}),
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value || value !== id) {
                    return t('info_informe_palavra',{value:id})
                }
            }
        })
        if (collectionName && collectionName === id) {
            await limparCadastro(id);
            stopLoadingAction(btn);
        }else{
            stopLoadingAction(btn);
        }
    }


    return (
        <>
            <Tooltip ref={refTooltip} target="[data-pr-tooltip]" />
            <Toast ref={toast} appendTo={"self"} position="bottom-center" />
            <div className="row m-0 mb-2" style={{height:'40px'}}>
                <div className="col-sm-6 text-left">
                    <div ref={refDivInserir} className="btn-group">
                        <button ref={refBtnInserir} type="button" data-pr-tooltip={t('inserir')} data-pr-position="top" className="btn btn-icon btn-light" onClick={(e) => newItem(e)}>
                            <i className="icon-file-plus"></i>
                        </button>
                        <button ref={refBtnImportar} type="button" data-pr-tooltip={t('importar_tipo', {tipo: 'XLSX'})} data-pr-position="top" className="btn btn-icon btn-light" onClick={(e) => importarXLSX(e)}>
                            <i className="icon-file-download text-success"></i>
                        </button>
                        <button ref={refBtnExportarXLSX} type="button" data-pr-tooltip={t('exportar_tipo', {tipo: 'XLSX'})} data-pr-position="top" className="btn btn-icon btn-light text-green" onClick={(e) => exportar_xlsx(e)}>
                            <i className="icon-file-excel"></i>
                        </button>
                    </div>
                    <div ref={refDivEditar} className="btn-group ml-2">
                        <button ref={refBtnEditar} type="button" data-pr-tooltip={t('modo_edicao')} data-pr-position="top" className="btn btn-icon btn-light" onClick={(e) => modoEditar(e)}>
                            <i className="icon-compose"></i>
                        </button>
                        <button ref={refBtnEditarConfirmar} type="button" data-pr-tooltip={t('confirmar')} data-pr-position="top" className="btn btn-icon btn-light" style={{display: 'none'}} onClick={(e) => modoEditarConfirmar(e)}>
                            <i className="icon-check text-success"></i>
                        </button>
                        <button ref={refBtnEditarCancelar} type="button" data-pr-tooltip={t('cancelar')} data-pr-position="top" className="btn btn-icon btn-light" style={{display: 'none'}} onClick={(e) => modoEditarCancelar(e)}>
                            <i className="icon-cross"></i>
                        </button>
                    </div>
                </div>
                <div ref={refDivRemover} className="col-sm-6 text-right">
                    <div className="btn-group mr-3">
                        <button ref={refBtnClearDuplicates} type="button" data-pr-tooltip={t('msg_limpar_duplicados')} data-pr-position="left" className="btn btn-icon btn-light text-danger" onClick={(e) => clearDuplicates(e)}>
                            <i className="icon-files-empty"></i>
                        </button>
                        <button ref={refBtnLimpar} type="button" data-pr-tooltip={t('msg_limpar_registros')} data-pr-position="left" className="btn btn-icon btn-danger" onClick={(e) => limpar(e).catch(err => console.error(err))}>
                            <i className="icon-trash-alt"></i>
                        </button>
                    </div>
                    <div className="btn-group">
                        <button ref={refBtnRemover} type="button" data-pr-tooltip={t('msg_remover_selecionados')} data-pr-position="left" className="btn btn-icon btn-light text-danger" onClick={(e) => remover(e).catch(err => {console.error(err)})}>
                            <i className="icon-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="row m-0 mb-2">
                <div className="col-sm-12">
                    <TableCrud
                        id={id}
                        ref={tableRef}
                        name={name}
                        configuration={configuration}
                        data={tableData}
                        columns={tableColumns}
                        editMode={editMode}
                        selectable={true}
                        pagination={true}
                        onCellEdited={onCellEdited}
                        initialSort={initialSort}
                        exportTable={{xlsx: true}}
                        persist={persistTable}
                    />
                </div>
            </div>



            {(footer) ? <>{footer()}</> : <></>}

            <ModalNew
                id={id}
                title={name}
                focus={focus}
                configuration={configuration}
                show={newModalShow}
                close={newModalClose}
            />

            <ModalImportarXLSX
                id={id}
                configuration={configuration}
                show={dropzoneModalShow}
                close={dropzoneModalClose}
                label={t('importar')}
                description="XLSX"
                accept={{
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
                }}
            />

            <ModalClearDuplicates
                id={id}
                configuration={configuration}
                show={clearModalShow}
                close={clearModalClose}
            />
        </>
    )

})

export default Crud