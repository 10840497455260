import configJSON from '../config/config.json'
import {createContext, useEffect, useState} from "react";

const ConfigContext = createContext({});

export const ConfigContextProvider = ({children}) => {
    const [config, setConfig] = useState({});
    useEffect(()=>{
        setConfig(configJSON)
    },[])
    return (
        <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
    )
}

export default ConfigContext