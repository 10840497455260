import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import { FixedSizeList as List } from 'react-window';

const height = 35;
const width = '100%';

const MenuList = ({ options, children, getValue }) => {
    const [value] = getValue();

    let initialOffset = 0;
    if (options.length > 0) {
        initialOffset = options.indexOf(value) * height;
    }

    if(options.length === 0){
        return (<></>)
    }

    return (
        <List
            height={height*5}
            itemCount={children.length}
            itemSize={height}
            initialScrollOffset={initialOffset}
            width={width}>
            {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
    );
};

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: 10
    })
};

const RHFSelect = forwardRef((props, ref) => {
    const {name, placeholder, options, control, handleChange, defaultValue, isClearable=true, isMulti=false, isDisabled=false} = props;
    const [optionValues, setOptionValues] = useState([]);

    const selectRef = useRef();
    useImperativeHandle(
        ref,
        () => ({
            setValue (value) {
                selectRef.current.setValue(value);
            },
            setOptions(opts) {
                setOptionValues(opts)
            },
            getOptions(){
                return options;
            },
            clearSelect () {
                selectRef.current.setValue(null);
            }
        }),
    )

    useEffect(()=>{
        setOptionValues(options);
    },[options])

    const handleSelectChange = (value, field) => {
        field.onChange(value);
        if(handleChange) handleChange(name, value)
    };

    return (
        <Controller

            render={({ field }) => (
                <Select
                    {...field}
                    ref={selectRef}
                    components={{MenuList}}
                    isClearable={isClearable}
                    isMulti={isMulti}
                    isDisabled={isDisabled}
                    placeholder={placeholder}
                    options={optionValues}
                    styles={customStyles}
                    onChange={(value) => handleSelectChange(value, field)}
                />
            )}

            name={name}
            control={control}
            defaultValue={defaultValue}
        />
    );
})

export default RHFSelect;