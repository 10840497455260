import React, {useEffect, useState, useContext} from "react";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import ServicoContext from "../context/ServicoContext";

import Loader from "./Loader";
import Crud from "./util/Crud";

export default function ModalCadastrarVagas(props){
    const {t} = useTranslation();

    const {id} = props;

    const {getStateCollection, listarCadastro} = useContext(ServicoContext);

    const [showModal, setShowModal] = useState(false);
    const [loader, setLoader] = useState({show:true, msg:''})

    const [data, setData] = useState([]);

    useEffect(() => {
        const init = async () => {
            await listarCadastro(id);
        }
        init().catch(err => {
            console.error(err);
        })
        return ()=> {
            setLoader({show:true, msg:"", error: false})
        }
    }, []);

    useEffect(()=>{
        setShowModal(props.show);
    },[props.show])


    useEffect(()=>{
        const list = getStateCollection(id);
        //console.log(id, "Atualizando Dados", id, list);
        setData(list);
        setTimeout(()=>{
            setLoader({show:false, msg:"", error: false})
        },500)
    },[getStateCollection(id)])

    const onEntered = ()=>{
        if(props.onOpen) props.onOpen()
    }
    const onExited = ()=>{
        if(props.onClose) props.onClose();
    }

    return (
        <Modal
            show={showModal}
            onExited={onExited}
            onEntered={onEntered}
            animation={true}
            dialogClassName="modal-full"
            enforceFocus={false}
        >
            <Loader show={loader.show} msg={loader.msg} type="modal" />
            <Modal.Header>
                <h6 className="font-weight-semibold">
                    <i className="icon-plus2 mr-2" />
                    {t('cadastrar_vagas')}
                </h6>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        {(id) ?
                            <>
                                <Crud
                                    key={id}
                                    id={id}
                                    name={t("vagas")}
                                    data={data}
                                    initialSort={[{column: "escola", dir: "asc"}]}
                                    persistTable={true}
                                    configuration={{
                                        "description": "",
                                        "table": [
                                            {"title": t('escola'), "field": "escola", "editor": "input", _type: "reference", _crud: "escolas", _crud_label: "nome", _empty: true},
                                            {"title": t('cargo'), "field": "cargo", "editor": "input", _type: "reference", _crud: "cargos", _crud_label: "nome", _empty: true},
                                            //{"title":"Tamanho", "field":"tamanho", "editor":"number", "sorter": "number", "_type": "number", "_fixed": 2, "formatter": "money", "formatterParams": {"decimal": ",","thousand": ".","precision": 2}}
                                            {"title": t('vagas'), "field": "vagas", editor: "number", sorter: "number", _type: "number", editorParams:{selectContents:true}}
                                        ]
                                    }}
                                />
                            </> : <></>}

                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={props.close}>{t('fechar')}</Button>
            </Modal.Footer>
        </Modal>
    )
}