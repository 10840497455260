import React, {useEffect, useState, useRef, forwardRef, useImperativeHandle, useContext} from "react";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import moment from 'moment';

import Loader from "./Loader";
import {useForm, FormProvider, useFormContext} from "react-hook-form";
import RHFMuiSelect from "./forms/RHFMuiSelect";
import WizardForm from "./forms/wizard/WizardForm";
import {notify} from "./Notify";
import {moduleControl} from "../control/Module";
import ComprovanteFase2 from "./ComprovanteFase2";
import ReactToPrint, {useReactToPrint} from "react-to-print";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import Swal from "sweetalert2";
import RHFCpf from "./forms/RHFCpf";
import ServicoContext from "../context/ServicoContext";
import RHFListDouble from "./forms/RHFListDouble";
import {Divider} from "rsuite";
import {useWizard} from "react-use-wizard";

const Step1 = ({vaga, vagas, servico, handleChange, close}) => {
    const {t} = useTranslation();
    const { control } = useFormContext();

    const {nextStep} = useWizard();

    return (
        <>
            <div className="pl-3 pr-3">
                <div className="row">
                    <div className="col-md-12">
                        <fieldset>
                            <div className="form-group mb-0 mt-1">
                                <RHFMuiSelect
                                    name="vaga"
                                    label={t('selecione')}
                                    control={control}
                                    options={vagas}
                                    handleChange={handleChange}
                                    defaultValue=""/>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="row pt-3" style={(!vaga) ? {display:'none'} : {display:'block'}}>
                    <div className="col-md-6">
                        <div className="card border-left-3 border-left-primary rounded-left-0">
                            <div className="card-body">
                                <div className="d-sm-flex align-item-sm-center flex-sm-nowrap">
                                    <div>
                                        <h6 className="font-weight-semibold">{(vaga) ? vaga.codigo : ''}</h6>
                                        <ul className="list list-unstyled mb-0">
                                            <li><span className="text-muted">{(vaga) ? vaga.descricao : ''}</span></li>
                                            <li>{t('uf')}: <span className="font-weight-semibold">{(vaga) ? vaga.uf : ''}</span></li>
                                            {(vaga && vaga.cidade !== '') ? <li>{t('cidade')}: <span className="font-weight-semibold">{vaga.cidade}</span></li> : ''}
                                            {(vaga && vaga.bairro !== '') ? <li>{t('bairro')}: <span className="font-weight-semibold">{vaga.bairro}</span></li> : ''}
                                            <li>{t('validade')}: <span className="font-weight-semibold">
                                                {(servico._module_options.validade && servico._module_options.validade !== '') ?
                                                    moment(servico._module_options.validade,'YYYYMMDDHHmm').format('DD/MM/YYYY HH:mm') : '--'}
                                            </span></li>
                                        </ul>
                                    </div>
                                    <div className="text-sm-right mb-0 mt-3 mt-sm-0 ml-auto">
                                        <h6 className="font-weight-semibold">{servico._module_options.portaria}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer d-sm-flex justify-content-sm-between align-items-sm-center">
                                {t('vagas')+':'}
                                <span className="font-weight-semibold text-success">
                                    {(vaga) ? vaga.quantidade : 0}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Divider />
            <div className="row">
                <div className="col-md-12 text-right">
                    <div className="btn-group">
                        {(vaga) ? <button onClick={() => nextStep()} type="button" className="btn btn-success">{t('proximo')} <i className="icon-arrow-right5 ml-2"></i></button> : ""}
                    </div>
                    <div className="btn-group ml-2">
                        <Button variant="light" onClick={close}>{t('fechar')}</Button>
                    </div>
                </div>
            </div>
        </>
    );
};
const Step2 = ({close, setInscricoes, servico, setLoader}) => {
    const {t} = useTranslation();
    const { register, control, watch, setFocus, formState: { errors }} = useFormContext();

    const {nextStep, previousStep} = useWizard();

    const watchCPF = watch("cpf");

    const goNextStep = async ()=>{
        setLoader({show: true,msg:t('verificando')})
        const tabs = await moduleControl.buscarDocumentos(servico._id,{
            cpf:watchCPF
        });
        if(!tabs || tabs.error || tabs.length === 0){
            notify('danger', t('cadastro'), t('info_cpf_nao_cadastrado'))
        }else{
            setInscricoes(tabs);
            await nextStep()
        }
        setLoader({show:false,msg:''})
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <fieldset>
                        <div className="form-group mb-1">
                            <label>{t('cpf')}</label>
                            <RHFCpf name="cpf"/>
                        </div>
                    </fieldset>
                </div>
            </div>
            <Divider/>
            <div className="row">
                <div className="col-md-12 text-right">
                    <div className="btn-group">
                        <button onClick={() => previousStep()} type="button" className="btn btn-light"><i className="icon-arrow-left5 mr-2"></i> {t('anterior')}</button>
                        {(!errors['cpf']) ? <button onClick={() => goNextStep()} type="button" className="btn btn-success">{t('proximo')} <i className="icon-arrow-right5 ml-2"></i></button> : ""}
                    </div>
                    <div className="btn-group ml-2">
                        <Button variant="light" onClick={close}>{t('fechar')}</Button>
                    </div>
                </div>
            </div>
        </>
    )
};
const Step3 = (props) => {
    const {t} = useTranslation();

    const {inscricoes, close, setInscricao, setLoader} = props

    const {register, control, watch, setFocus, formState: {errors}} = useFormContext();
    const {nextStep, previousStep} = useWizard();

    const inscricaoRef = useRef();
    const btnEnviar = useRef();
    useEffect(()=>{
        setFocus('inscricao');
    },[])

    const watchInscricao = watch("inscricao");

    const reenviar = async ()=>{
        setLoader({show: true,msg:t('enviando')})
        btnEnviar.current.disabled = true;
        for(const [,tab] of inscricoes.entries()){
            await moduleControl.enviarEmailWarning({
                to:tab.email,
                subject:t('inscricao')+' - '+tab.uuid,
                body:[
                    t('inscricao')+': <b>'+tab.uuid+'</b>',
                    tab.escola_atual,
                    tab.cargo_atual
                ].join('<br>')
            });
        }
        btnEnviar.current.disabled = false;
        notify('info', t('inscricao'), t('info_email_enviado'))
        setLoader({show: false,msg:""})
    }

    const goNextStep = async ()=>{
        setLoader({show: true,msg:t('verificando')})
        let inscricaoValida = false;
        for(const [,tab] of inscricoes.entries()){
            if(tab.uuid === watchInscricao){
                inscricaoValida = true;
                setInscricao(tab);
                break
            }
        }
        if(!inscricaoValida){
            setLoader({show: false,msg:""})
            notify('danger', t('cadastro'), t('info_nenhum_numero_inscricao_cpf'))
        }else{
            setLoader({show: false,msg:""})
            await nextStep()
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <fieldset>
                        <div className="form-group mb-5">
                            <label>{t('numero_inscricao')}</label>
                            <input type="text" className="form-control form-control-sm" ref={inscricaoRef} placeholder="" {...register("inscricao")} />
                        </div>
                        <div className="form-group mb-1">
                            <label>{t('info_reenviar_inscricao_email')}</label>
                            <div><Button ref={btnEnviar} variant="light" onClick={reenviar}>{t('reenviar')}</Button></div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <Divider/>
            <div className="row">
                <div className="col-md-12 text-right">
                    <div className="btn-group">
                        <button onClick={() => previousStep()} type="button" className="btn btn-light"><i className="icon-arrow-left5 mr-2"></i> {t('anterior')}</button>
                        <button onClick={() => goNextStep()} type="button" className="btn btn-success">{t('proximo')} <i className="icon-arrow-right5 ml-2"></i></button>
                    </div>
                    <div className="btn-group ml-2">
                        <Button variant="light" onClick={close}>{t('fechar')}</Button>
                    </div>
                </div>
            </div>
        </>
    )
};

const Step4 = (props) => {
    const {t} = useTranslation();

    const {inscricao, servico, close} = props;

    const {getStateCollection} = useContext(ServicoContext);

    const {register, control, watch,setFocus, formState: {errors}} = useFormContext();

    const {previousStep} = useWizard();

    const [escolasOptions, setEscolasOptions] = useState([]);
    const [escolaCargoMap, setEscolaCargoMap] = useState({});

    useEffect(() => {
        const list = getStateCollection("vagas." + servico.tab);
        const _escolaCargoMap = {}
        for (const [, item] of list.entries()) {
            if (!_escolaCargoMap[item.escola]) _escolaCargoMap[item.escola] = {cargos:{}}
            if(!_escolaCargoMap[item.escola].cargos[item.cargo]) _escolaCargoMap[item.escola].cargos[item.cargo] = item.vagas;
        }
        setEscolaCargoMap(_escolaCargoMap);
    },[getStateCollection("vagas."+servico.tab)])

    useEffect(()=>{
        const list = getStateCollection('escolas');
        const _escolasOpts = []
        for(const [,item] of list.entries()){
            const escola = escolaCargoMap[item.nome];
            if(escola && escola.cargos && escola.cargos.hasOwnProperty(inscricao.cargo_atual) && item.nome.toLowerCase() !== 'adido' && item.nome !== inscricao.escola_atual){
                _escolasOpts.push({label:item.nome, value:item.nome})
            }
        }
        setEscolasOptions(_escolasOpts);
    },[getStateCollection('escolas'), escolaCargoMap])

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <fieldset>
                        <div className="form-group mb-1">
                            <label>{t('escola_atual')}: <b>{inscricao.escola_atual}</b></label>
                        </div>
                        <div className="form-group mb-1">
                            <label>{t('cargo_atual')}: <b>{inscricao.cargo_atual}</b></label>
                        </div>
                        <div className="form-group mb-1">
                            <label>{t('info_escolas_ordem')}</label>
                            <RHFListDouble control={control} name={"escolas"} options={escolasOptions} availableHeader={t('escolas')} selectedHeader={t('escolas_selecionadas')} ></RHFListDouble>
                        </div>
                    </fieldset>
                </div>
            </div>
            <Divider/>
            <div className="row">
                <div className="col-md-12 text-right">
                    <div className="btn-group">
                        <button onClick={() => previousStep()} type="button" className="btn btn-light"><i className="icon-arrow-left5 mr-2"></i> {t('anterior')}</button>
                    </div>
                    <div className="btn-group ml-2">
                        <Button variant="light" onClick={close}>{t('fechar')}</Button>
                    </div>
                </div>
            </div>
        </>
    )
};
const Step5 = forwardRef((props, ref) => {
    const {t} = useTranslation();

    const {vaga, escolas, inscricao, servico, close} = props;

    const comprovanteRef = useRef();

    const reactToPrintFn = useReactToPrint({ contentRef :comprovanteRef });

    const salvarPDF = async () => {
        const canvas = await html2canvas(comprovanteRef.current);
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save("comprovante.pdf");
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header bg-transparent header-elements-inline py-0">
                            <h6 className="card-title py-3">{t('comprovante')}</h6>
                            <div className="header-elements">
                                <button type="button" onClick={(e) => salvarPDF(e)} className="btn btn-light btn-sm">
                                    <i className="icon-file-check mr-2"></i> {t('salvar')}
                                </button>
                                <button type="button" onClick={reactToPrintFn} className="btn btn-light btn-sm ml-3">
                                    <i className="icon-printer mr-2"></i> {t('imprimir')}
                                </button>
                            </div>
                        </div>
                        <ComprovanteFase2 key='comp' mail={true} ref={comprovanteRef} servico={servico} escolas={escolas} vaga={vaga} inscricao={inscricao}/>
                    </div>
                </div>
            </div>
            <Divider/>
            <div className="row">
                <div className="col-md-12 text-right">
                    <div className="btn-group ml-2">
                        <Button variant="light" onClick={close}>{t('fechar')}</Button>
                    </div>
                </div>
            </div>
        </>
    )
});

export default function ModalAbrirProcesso(props){
    const {t} = useTranslation();

    const [loader, setLoader] = useState({show:false, msg:''})
    const [showModal, setShowModal] = useState(false);
    const [servico, setServico] = useState({});
    const [vagas, setVagas] = useState([]);

    const [vaga, setVaga] = useState(null);
    const [inscricoes, setInscricoes] = useState([]);
    const [inscricao, setInscricao] = useState({});
    const [escolas, setEscolas] = useState({});

    const methods = useForm();
    const {reset, watch, getValues, formState: { errors }} = methods;

    const watchVaga = watch("vaga");
    const watchEscolas = watch("escolas");

    useEffect(()=>{
        setShowModal(props.show);
        setServico(props.servico);
        if(props.servico && props.servico._module_vagas){
            const list = []
            for(const codigo of Object.keys(props.servico._module_vagas)){
                let descricao = props.servico._module_vagas[codigo].descricao;
                if(!descricao || descricao.trim() === ''){
                    descricao = codigo;
                }
                list.push({value:codigo, name:descricao})
            }
            setVagas(list);
        }
    },[props.show, props.servico])

    const onEntered = ()=>{
        if(props.onOpen) props.onOpen(servico);
    }
    const close = ()=>{
        if(props.close) props.close(servico);
    }
    const onExited = ()=>{
        reset();
        setVaga(null);
        if(props.onClose) props.onClose(servico);
    }

    useEffect(()=>{
        if(props.servico && props.servico._module_vagas && props.servico._module_vagas[watchVaga]){
            const item = props.servico._module_vagas[watchVaga];
            setVaga(item);
        }
    },[watchVaga])

    const finalizar = async (e)=>{
        setLoader({show:true, msg:t('cadastrando')})
        const fields = {...getValues()}
        const rs = await moduleControl.atualizarDocumentos(servico._id, inscricao.uuid, {escolas_selecionadas:fields.escolas});
        if(rs.error){
            notify('warning', t('processo'), t('error_indicacao'))
        }else{
            setEscolas(fields.escolas);
            await goToStep(stepCount-1)
        }
        setLoader({show:false})
    }

    const [activeStep, setActiveStep] = useState(false)
    const [stepCount, setStepCount] = useState(false)

    const wizardRef = useRef(null);
    const listenerStep = (props)=>{
        setActiveStep(props.activeStep)
        setStepCount(props.stepCount);
    }

    const goToStep = async (index)=>{
        await wizardRef.current.goToStep(index)
    }


    return (
        <Modal
            show={showModal}
            onExited={onExited}
            onEntered={onEntered}
            animation={true}
            dialogClassName="modal-full"
        >
            <Loader show={loader.show} msg={loader.msg} type="modal" />
            <FormProvider {...methods} >
                <Modal.Header className="bg-light p-2 pl-3">
                    <h6 className="font-weight-semibold m-0">
                        {servico.nome}
                        <small className="d-block text-muted">{(servico.descricao) ? servico.descricao : ''}</small>
                    </h6>
                </Modal.Header>
                <Modal.Body className="pt-1">
                    <div className="row">
                        <div className="col-md-12">
                            <WizardForm listenerStep={listenerStep} ref={wizardRef} steps={
                                [
                                    <Step1 action={t('selecione')} name="vaga" vagas={vagas} vaga={vaga} servico={servico} close={close} setLoader={setLoader}/>,
                                    <Step2 action={t('info_informe_cpf')} name="cpf"  servico={servico} setInscricoes={setInscricoes} close={close} setLoader={setLoader}/>,
                                    <Step3 action={t('info_numero_inscricao')} name="inscricao" inscricoes={inscricoes} setInscricao={setInscricao}  close={close} setLoader={setLoader}/>,
                                    <Step4 action={t('indicacao')} name="indicacao" inscricao={inscricao} servico={servico}  close={close} setLoader={setLoader}/>,
                                    <Step5 action={t('comprovante')} name="comprovante" inscricao={inscricao} escolas={escolas} vaga={vaga} servico={servico} close={close} setLoader={setLoader}/>
                                ]
                            } />
                        </div>
                    </div>

                    {(vaga && !errors['cpf'] && watchEscolas && watchEscolas.length > 0 && activeStep === 3) ?
                        <>
                            <Divider />
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <Button className="btn-lg" variant="primary" onClick={(e)=>{finalizar(e).catch(err=>{console.error(err)})}}>{t('cadastrar')}</Button>
                                </div>
                            </div>
                        </>
                        : ''}
                </Modal.Body>
            </FormProvider>
        </Modal>
    )
}