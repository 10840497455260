import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import Loader from "./../Loader";
import {useSearchParams} from "react-router-dom";
import {moduleControl} from "../../control/Module";
import {notify} from "../Notify";

const Cancelar = props => {
    const {t} = useTranslation();

    const [loader, setLoader] = useState({show:true, msg:t('cancelando_inscricao')})

    const [searchParams, setSearchParams] = useSearchParams();
    const [vaga, setVaga] = useState('')
    const [cpf, setCPF] = useState('')
    const [error, setError] = useState(false)

    useEffect(()=>{
        const token = searchParams.get("token");
        const cancelar = async ()=>{
            return await moduleControl.cancelarDocumentos(token);
        }
        cancelar().then((rs)=>{
            if(rs.error){
                notify('danger', t('cadastro'), t('error_cancelar_inscricao'))
                setError(true)
            }
            setCPF(rs.filter.cpf)
            setVaga(rs.filter.vaga)
            setLoader({show:false,msg:''})
        }).catch(err=>{
            console.log(err)
            setLoader({show:false,msg:''})
        });
    },[searchParams, t])

    return (
        <>
            <Loader show={loader.show} msg={loader.msg} type="content" />
            <div className="font-weight-bold text-center w-100">
                {(!error) ? t('inscricao_cancelada'): t('error_cancelar_inscricao')}
            </div>
            <div className="font-weight-bold text-center w-100">{t('cadastro')}: {vaga}</div>
            <div className="font-weight-bold text-center w-100">{t('cpf')}: {cpf}</div>
        </>
    )
}

export default Cancelar