import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react'
import {useTranslation} from "react-i18next";

import Loader from "./Loader";
import tableLang from '../locales/tabulator.loc.json'

import {TabulatorFull as Tabulator} from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";
import {Tooltip} from '@mui/material';

const Tabela = forwardRef((props, ref) => {
    const {t} = useTranslation();
    const {name, data, columns, rowSelectionChanged, onEnterKeyPressed} = props;

    const [loader,setLoader] = useState({show:true,msg:t('carregando')})
    const [tabulator, setTabulator] = useState(null);
    const tabulatorRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            updateData(data) {
                tabulator.updateData([data]);
            },
            nextRow(){
                nextRow()
            }
        }),
    )

    const options = {
        index:"_id",
        height:"100%",
        selectableRows:1,
        data,
        columns,
        //selectableRangeMode:"click",
        keybindings:{
            "enterKey": "13"
        },
        pagination: 'local',
        paginationSize: 25,
        paginationSizeSelector: [10, 25, 50, 100, true],
        layout: "fitColumns",
        reactiveData:true,
        locale:'pt-br',
        langs:tableLang,
        addRowPos:'top'
    };

    const nextRow = ()=>{
        const t = tabulator.getPageMax();
        const n = tabulator.getPage();

        const selectedRows = tabulator.getSelectedRows();
        if (!selectedRows.length) return;

        const lastSelectedRow = selectedRows[selectedRows.length-1];
        const lastRowPosition = lastSelectedRow.getPosition(true);
        const nextRow = lastSelectedRow.getNextRow();
        if (!nextRow) {
            if(n < t) {
                tabulator.nextPage();
                const nextRow = tabulator.getRowFromPosition(lastRowPosition+1, true);
                tabulator.deselectRow();
                tabulator.selectRow(nextRow);
                return true;
            }else{
                return false
            }
        }else{
            tabulator.deselectRow();
            tabulator.selectRow(nextRow);
            return true;
        }
    }

    const previousRow = ()=>{
        const n = tabulator.getPage();

        const selectedRows = tabulator.getSelectedRows();
        if (!selectedRows.length) return;

        const lastSelectedRow = selectedRows[selectedRows.length-1];
        const lastRowPosition = lastSelectedRow.getPosition(true);
        const prevRow = lastSelectedRow.getPrevRow();
        if (!prevRow) {
            if(n > 1){
                tabulator.previousPage();
                const prevRow = tabulator.getRowFromPosition(lastRowPosition-1, true);
                tabulator.deselectRow();
                tabulator.selectRow(prevRow);
                return true;
            }else{
                return false;
            }
        }else{
            tabulator.deselectRow();
            tabulator.selectRow(prevRow);
            return true;
        }
    }

    const enterKey = ()=>{
        const selectedRows = tabulator.getSelectedRows();
        if (!selectedRows.length) return;

        const current = selectedRows[0];
        const rowData = current.getData();
        if(onEnterKeyPressed) onEnterKeyPressed(rowData);
    }

    Tabulator.extendModule("keybindings", "actions", {
        navUp: (e)=>{
            e.preventDefault();
            previousRow();
        },
        navDown: (e)=>{
            e.preventDefault();
            nextRow()
        },
        enterKey: (e)=>{
            e.preventDefault();
            enterKey()
        }
    });

    useEffect(()=>{
        const tabulatorInstance = new Tabulator(tabulatorRef.current, options);
        tabulatorInstance.on("rowSelectionChanged", (data, rows)=>{
            if(rowSelectionChanged) rowSelectionChanged(data, rows);
        });
        tabulatorInstance.on("tableBuilt", ()=>{
            tabulatorInstance.deselectRow()
            //const row = _tabulator.getRowFromPosition(0,true);
            //if(row) row.select();
        });
        setTabulator(tabulatorInstance)
        setLoader({show:false,msg:''})
    },[props.data])

    const exportar_pdf = ()=>{
        tabulator.download("pdf", name+".pdf", {
            orientation:"landscape", //set page orientation to portrait
            autoTable:(doc)=>{
                return {};
            },
        });
    }
    const exportar_xlsx = ()=>{
        tabulator.download("xlsx", name+".xlsx", {});
    }

    return (
        <>
            <Loader show={loader.show} msg={loader.msg} type="content" />
            <div className="card mb-2">
                <div className="card-header header-elements-inline bg-light p-1 pl-2">
                    <span className="card-title font-weight-semibold">{name}</span>
                    <div className="header-elements">
                        <Tooltip title={t('exportar_pdf')} arrow>
                            <button style={{marginRight:"2px"}} type="button" className="btn btn-secondary-100 text-danger btn-icon" onClick={(e)=>exportar_pdf(e)}>
                                <i className="icon-file-pdf"></i>
                            </button>
                        </Tooltip>
                        <Tooltip title={t('exportar_xlsx')} arrow>
                            <button type="button" className="btn btn-secondary-100 text-success btn-icon" onClick={(e)=>exportar_xlsx(e)}>
                                <i className="icon-file-excel"></i>
                            </button>
                        </Tooltip>
                    </div>
                </div>
                <div className="noselect" ref={tabulatorRef}></div>
            </div>
        </>
    )
})

export default Tabela