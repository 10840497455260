import {Wizard} from "react-use-wizard";
import WizStep from "./WizStep";
import WizHeader from "./WizHeader";
import WizFooter from "./WizFooter";
import WizAnimatedStep from "./WizAnimatedStep";
import {AnimatePresence} from "framer-motion";
import {forwardRef, useImperativeHandle, useRef} from "react";

const WizardForm = forwardRef(({steps, listenerStep}, ref) => {

    const footerRef = useRef(null)

    useImperativeHandle(ref, () => ({
        nextStep: footerRef.current.nextStep,
        previousStep: footerRef.current.previousStep,
        goToStep:footerRef.current.goToStep
    }), [ ])

    return (
        <Wizard startIndex={0}
                header={<WizHeader steps={steps} />}
                footer={<WizFooter listenerStep={listenerStep} key="footer" ref={footerRef}/>}
                wrapper={<AnimatePresence initial={false} mode="wait" />} >

            {steps.map((step)=>{
                return <WizStep key={step.props.name}>{step}</WizStep>
                //return (<WizAnimatedStep key={step.props.name}><WizStep>{step}</WizStep></WizAnimatedStep>)
            })}
        </Wizard>
    )
})

export default WizardForm