import EventEmitter from 'events';

class Auth extends EventEmitter{

    init(userStorage){
        this.clear()
        this.userStorage = userStorage;
        return !!this.userStorage;
    }

    async signIn(opts){
        const url = window.ENV.INBOX_API+'/v1/auth/token';
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({...opts}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        if(response.ok){
            const rs = await response.json();
            this.emit('signIn', rs)
            return rs;
        }else{
            const data = await response.json();
            return {
                error:data.message,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async validate(token){
        const url = window.ENV.INBOX_API+'/v1/auth/validate';
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data.message,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async adm(token){
        const url = window.ENV.INBOX_API+'/v1/auth/adm';
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data.message,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async signOut(user){
        this.emit('signOut', user)
        user.invalid = true;
        return user;
    }

    clear(event){
        if(!event) this.removeAllListeners()
        else this.removeAllListeners(event)
    }

}



export const authControl = new Auth()
export default Auth