import React, {useContext, useEffect, useRef, useState} from 'react'
import {useTranslation} from "react-i18next";
import ControlContext from "../../context/ControlContext";
import Crud from "../util/Crud";
import ServicoContext from "../../context/ServicoContext";
import ContentLoader from "../util/ContentLoader";

const Escolas = props => {
    const {t} = useTranslation();

    const id = "escolas";

    const {setLeftSidebar} = useContext(ControlContext);
    const {getStateCollection, listarCadastro} = useContext(ServicoContext);
    const [loader, setLoader] = useState({show:true, msg:"", error:false});

    const [data, setData] = useState([]);

    useEffect(() => {
        const init = async () => {
            await listarCadastro(id);
        }
        init().catch(err => {
            console.error(err);
        })
        setLeftSidebar({
            "professores": {"title": t('profissionais')},
            "escolas": {"title": t('escolas')},
            "cargos": {"title": t('cargos')}
        });
        return ()=> {
            setLeftSidebar(null)
            setLoader({show:true, msg:"", error: false})
        }
    }, []);

    useEffect(()=>{
        const list = getStateCollection(id);
        //console.log(id, "Atualizando Dados", list);
        setData(list);
        setTimeout(()=>{
            setLoader({show:false, msg:"", error: false})
        },500)
    },[getStateCollection(id)])


    return (
        <>
            <ContentLoader show={loader.show} msg={loader.msg} error={loader.error} type="view"/>
            <div className="mt-2" style={{height: "90%", overflowY: "auto"}}>
                <Crud
                    id={id}
                    name={t("escolas")}
                    initialSort={[{column: "nome", dir: "asc"}]}
                    data={data}
                    configuration={{
                        "description": "",
                        "table": [
                            {"title": "_id", "field": "_id", "visible": false, "_type": "id"},
                            {"title": t('nome'), "field": "nome", "editor": "input"},
                            {"title": t('descricao'), "field": "descricao", "editor": "input"}
                        ]
                    }}
                />
            </div>
        </>
    )
}

export default Escolas