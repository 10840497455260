import $ from "jquery";
export default function Util(){}

export const startLoadingAction = (btn, css)=>{
    if(!btn) return false;
    btn.disabled = true;
    if(!css) css = '';
    let icon = $(btn).find('i');
    icon.data('lastclass',icon.attr('class'));
    icon.removeClass().addClass('icon-spinner4 spinner disabled '+css);
    return true;
}

export const stopLoadingAction = (btn)=>{
    if(!btn) return;
    let icon = $(btn).find('i');
    icon.removeClass().addClass(icon.data('lastclass'));
    btn.disabled = false;
}
