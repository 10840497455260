const Storage = class {

    constructor() {
        this.id = 'inboxz_modules'
    }

    set(type,key,item){
        let storage = window.sessionStorage;
        if(type === 'local'){
            storage = window.localStorage;
        }
        storage.setItem(this.id+'.'+key,JSON.stringify(item));
    }

    onAuthStateChange(){}

    get(type,key){
        let storage = window.sessionStorage;
        if(type === 'local'){
            storage = window.localStorage;
        }
        let valor = storage.getItem(this.id+'.'+key);
        if(valor){
            return JSON.parse(valor);
        }else{
            return null;
        }
    }

    remove(type,key){
        let storage = window.sessionStorage;
        if(type === 'local'){
            storage = window.localStorage;
        }
        storage.removeItem(this.id+'.'+key);
    }

    clear(){
        window.sessionStorage.clear();
        window.localStorage.clear();
    }
}
export default new Storage()