import React, {useEffect, useState, useContext, useRef} from 'react'
import {useTranslation} from "react-i18next";

import Tabela from "../Tabela";
import Arquivo from "../Arquivo";
import {SplitPane} from "react-collapse-pane";
import Loader from "../Loader";

import AuthContext from "../../context/AuthContext";
import {moduleControl} from "../../control/Module";
import ServicoContext from "../../context/ServicoContext";
import RHFMuiSelect from "../forms/RHFMuiSelect";
import {useForm, FormProvider} from "react-hook-form";
import RHFCheckbox from "../forms/RHFCheckbox";
import RHFCpf from "../forms/RHFCpf";
import {Button} from "react-bootstrap";
import {notify} from "../Notify";
import Swal from "sweetalert2";

const Validar = (props) => {

    const {t} = useTranslation();
    const {user} = useContext(AuthContext);
    const {servicos} = useContext(ServicoContext);
    const [servico, setServico] = useState(null);
    const [loader, setLoader] = useState({show:true,msg:t('carregando')})
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState(null)
    const [optionsServicos, setOptionsServicos] = useState([])
    const [servicoMap, setServicoMap] = useState([])

    const tabelaRef = useRef();

    const methods = useForm();
    const {control, watch} = methods;

    const columns = [
        { title: t('cpf'), field: "cpf"},
        { title: t('cadastro'), field: "_vaga.descricao" },
        { title: t('documento'), field: "tipo_documento" },
        { title: t('valido'), field: "valid", formatter:"tickCross", sorter:"boolean", hozAlign:"center", vertAlign:"middle", headerHozAlign:"center" },
        { title: t('data_validacao'), field: "valid_date", formatter:"datetime", formatterParams:{
                inputFormat:"iso",
                outputFormat:"dd/MM/yyyy HH:mm",
                invalidPlaceholder:"(invalid date)",
                timezone:"utc"
            }, hozAlign:"center", headerHozAlign:"center" },
        { title: t('avaliador'), field: "valid_user", sorter: "number", hozAlign:"center", headerHozAlign:"center" },
        { title: t('pontos'), field: "pontos", sorter: "number", hozAlign:"center", headerHozAlign:"center" }
    ];

    const onSubmit = async (data, e)=>{
        setLoader({show:true,msg:t('carregando')})
        if(!data.servico){
            notify('warning', t('processo'), t('info_campo_vazio',{campo:t('processo')}))
            setLoader({show:false,msg:''})
            return;
        }
        const filter = {}
        if(data.sem_avaliacao){
            filter.$or = [{"valid_user":{$exists:false}},{"valid_user":""}]
        }
        if(data.cpf && data.cpf.trim() !== ''){
            filter.cpf = data.cpf;
        }
        const rs = await moduleControl.listarDocumentos(data.servico, user.token, filter);
        if(rs.error){
            notify('danger', t('documentos'), t('error_problema_buscar_documentos'))
            setLoader({show:false,msg:''})
            return;
        }
        setServico(servicoMap[data.servico]);
        setData(rs);
        setLoader({show:false,msg:''})
    }

    const rowSelectionChanged = (data, rows)=>{
        if(data && data.length > 0){
            setSelected(data[0])
        }else{
            setSelected(null);
        }
    }
    const onEnterKeyPressed = (data)=>{
        if(data && data._id){
            validar(true, data).then(()=>{}).catch(err=>console.error(err));
        }
    }

    useEffect(()=>{
        const list = []
        const map = {}
        if(servicos){
            list.push({value:"",name:""})
            for(const [,servico] of servicos.entries()){
                if(servico._module_options && servico._module_options.cadastro) continue;
                if(servico._perm && servico._perm.editar){
                    map[servico._id] = servico;
                    list.push({value:servico._id,name:servico.nome})
                }
            }
            setServicoMap(map);
            setOptionsServicos(list);
            setLoader({show:false,msg:''})
        }
    },[servicos])

    const validar = async (valid, doc)=>{
        let rs = null
        if(servico){
            if(doc){
                rs = await preValidar(servico, doc, valid)
            }else{
                if(selected){
                    rs = await preValidar(servico, selected, valid)
                }
            }
        }
        if(rs && !rs.error){
            tabelaRef.current.updateData(rs);
            tabelaRef.current.nextRow();
        }
    }

    const preValidar = async (servico, doc, valid)=>{
        let pontos = 0;
        let rs = null;
        if(valid){
            let confirmar = true;
            if(doc.vaga && doc.tipo_documento &&
                servico._module_documentos &&
                servico._module_documentos[doc.vaga] &&
                servico._module_documentos[doc.vaga][doc.tipo_documento]){

                const conf = servico._module_documentos[doc.vaga][doc.tipo_documento]
                pontos = conf.pontos;
                if(conf.validar){
                    confirmar = false;
                }
            }

            if(confirmar){
                const pop = await Swal.fire({
                    title: t('pontuacao'),
                    input: 'text',
                    inputLabel: t('pontos'),
                    inputValue: pontos,
                    inputValidator: (value) => {
                        if (!value || isNaN(value)) {
                            return t('info_campo_invalido',{campo:t('pontos')})
                        }
                    },
                    showCancelButton: true,
                    confirmButtonText: t('sim'),
                    cancelButtonText: t('cancelar')
                })
                if (pop.isConfirmed) {
                    pontos = parseInt(pop.value)
                    setLoader({show:true,msg:t('carregando')})
                    rs = await moduleControl.validarDocumento(user.token, servico.tab, doc._id, valid, pontos);
                    setLoader({show:false,msg:''})
                }
            }else{
                setLoader({show:true,msg:t('carregando')})
                rs = await moduleControl.validarDocumento(user.token, servico.tab, doc._id, valid, pontos);
                setLoader({show:false,msg:''})
            }
        }else{
            setLoader({show:true,msg:t('carregando')})
            rs = await moduleControl.validarDocumento(user.token, servico.tab, doc._id, valid, pontos);
            setLoader({show:false,msg:''})
        }
        return rs
    }

    return (
        <>
            <Loader show={loader.show} msg={loader.msg} type="grid" />
            <FormProvider {...methods} >

                <SplitPane split="vertical" initialSizes={[1,1]}>
                    <div className="p-2">
                        <form key="cadastro" onSubmit={methods.handleSubmit(onSubmit)}>
                            <div className="card mt-2 mb-2">
                                <div className="card-header bg-light p-2">
                                    <span className="card-title font-weight-semibold">{t('cadastro')}</span>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-2">
                                                <RHFMuiSelect
                                                    name="servico"
                                                    label={t('selecione_o',{item:t('processo')})}
                                                    control={control}
                                                    options={optionsServicos}
                                                    defaultValue=""/>
                                            </div>
                                            <div className="form-group mb-2">
                                                <label>{t('cpf')}</label>
                                                <RHFCpf name="cpf" showError={false}/>
                                            </div>
                                            <div className="form-group mb-1">
                                                <label>{t('sem_avaliacao')}</label>
                                                <RHFCheckbox control={control} name="sem_avaliacao" />
                                            </div>
                                            <div className="form-group mb-2">
                                                <Button type="submit"  variant="primary">{t('pesquisar')}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <Tabela
                            name={t('documentos')}
                            ref={tabelaRef}
                            data={data}
                            columns={columns}
                            rowSelectionChanged={rowSelectionChanged}
                            onEnterKeyPressed={onEnterKeyPressed} />
                    </div>
                    <div className="p-2">
                        {(selected)?
                            <div className="form-group mb-2">
                                <button type="button" className="btn btn-success btn-sm"
                                        onClick={(e)=>validar( true)}>{t('validar')}</button>
                                <button type="button" className="btn btn-warning btn-sm ml-2"
                                        onClick={(e)=>validar( false)}>{t('invalidar')}</button>
                            </div>
                            :
                            <div className="mt-3 text-center text-muted font-size-lg">{t('selecione_o', {item: t('documento')})}</div>
                        }
                        <Arquivo servico={servico} documento={selected} />
                    </div>
                </SplitPane>
            </FormProvider>
        </>
    )
}

export default Validar