import React, { useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Inputmask from "inputmask";
import { useTranslation } from "react-i18next";

const MaskCPF = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;

const RHFCpf = ({ name, showError = true, defaultValue = "", className = "form-control form-control-sm " }) => {

    const { t } = useTranslation();
    const { control, watch, setError, clearErrors, formState: { errors }, setFocus } = useFormContext();
    const watchField = watch(name);
    const inputMaskRef = useRef();

    const validate = () => {
        return MaskCPF.test(watchField);
    };

    useEffect(() => {
        if (inputMaskRef.current) {
            const im = new Inputmask("999.999.999-99");
            im.mask(inputMaskRef.current);
        }

        if (!validate()) {
            setError(name, { type: "manual", message: t('info_campo_invalido', { campo: t(name) }) });
        } else {
            clearErrors(name);
        }
    }, []);

    useEffect(() => {
        if (!validate()) {
            setError(name, { type: "manual", message: t('info_campo_invalido', { campo: t(name) }) });
        } else {
            clearErrors(name);
        }
    }, [watchField]);

    return (
        <Controller
            render={({ field }) => (
                <input
                    {...field}
                    ref={inputMaskRef}
                    className={className + ((errors[name] && showError) ? " border-danger" : "")}
                />
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
        />
    );
};

export default RHFCpf;
