import EventEmitter from "events";

class Module extends EventEmitter{


    async loadDom(name){
        const response = await fetch(process.env.PUBLIC_URL+'/config/doms/'+name+'.txt')
        if(response.ok){
            return await response.text()
        }else{
            return ""
        }
    }

    async listarServicos(token){
        const url = window.ENV.INBOX_API+'/v1/modules/servico/listar/processos';
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        if(token) headers['x-access-token'] = token
        const response = await fetch(url, {
            method: 'GET',
            headers: headers
        })
        if(response.ok){
            const rs = await response.json();
            this.emit('onList', rs)
            return rs
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async novoServico(id, token, data){
        const url = window.ENV.INBOX_API+'/v1/modules/servico/processos/'+id;
        const response = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            const rs = await response.json();
            this.emit('onInsert', rs)
            return rs
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async removerServico(idServico, token){
        const url = window.ENV.INBOX_API+'/v1/modules/servico/processos/'+idServico;
        const response = await fetch(url, {
            method: 'DELETE',
            body: JSON.stringify({}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            const rs = await response.json();
            this.emit('onDelete', rs)
            return rs
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async editarServico(idServico, token, data){
        const url = window.ENV.INBOX_API+'/v1/modules/servico/processos/'+idServico;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            const rs = await response.json();
            this.emit('onEdit', rs)
            return rs
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async security(id, token, userid){
        const url = window.ENV.INBOX_API+'/v1/modules/security';
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({id,userid}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async salvarDocumentos(idServico, chave, tabs, files, uuid){
        const url = window.ENV.INBOX_API+'/v1/modules/documento/salvar/processos/'+idServico;
        const formData = new FormData()
        for(const [,file] of files.entries()){
            formData.append('files', file)
        }
        formData.append('chave', JSON.stringify(chave))
        formData.append('tabs', JSON.stringify(tabs))
        formData.append('uuid', uuid)
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json'
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async atualizarDocumentos(idServico, uuid, fields={}){
        const url = window.ENV.INBOX_API+'/v1/modules/documento/update/processos/'+idServico;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({uuid,fields}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async buscarDocumentos(idServico, data){
        const url = window.ENV.INBOX_API+'/v1/modules/documento/buscar/processos/'+idServico;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async countDocumentos(tab, filter, group){
        const url = window.ENV.INBOX_API+'/v1/modules/documento/count/processos/'+tab;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({filter,group}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async enviarEmailCancelamento(idServico, vaga, cpf, email){
        const url = window.ENV.INBOX_API+'/v1/modules/email/cancelar/processos/'+idServico;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                email,
                filter:{vaga,cpf},
                link:window.ENV.HOSTNAME+'/cancelar'
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async enviarEmailWarning(email){
        const url = window.ENV.INBOX_API+'/v1/modules/email/warning/processos';
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                email
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async cancelarDocumentos(token){
        const url = window.ENV.INBOX_API+'/v1/modules/documento/cancelar/processos/'+token;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async listarDocumentos(idServico, token, filter, group){
        const url = window.ENV.INBOX_API+'/v1/modules/documento/listar/processos/'+idServico;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({filter,group}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async abrirDocumento(token, tab, idDocumento){
        const url = window.ENV.INBOX_API+'/v1/modules/documento/abrir/processos/'+tab+'/'+idDocumento;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            return await response.blob();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async validarDocumento(token, tab, idDocumento, valid, pontos){
        const url = window.ENV.INBOX_API+'/v1/modules/documento/validar/processos/'+tab+'/'+idDocumento;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({valid,pontos}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async relatorio(token, group, filter, idServico){
        const url = window.ENV.INBOX_API+'/v1/modules/documento/relatorio/processos';
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({group, filter, servico:idServico}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async salvarCadastro(token, options){
        const url = window.ENV.INBOX_API+'/v1/modules/cadastro/salvar/processos';
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async removerCadastro(token, options){
        const url = window.ENV.INBOX_API+'/v1/modules/cadastro/remover/processos';
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async listarCadastroNoToken(options){
        const url = window.ENV.INBOX_API+'/v1/modules/cadastro/_listar/processos';
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async listarCadastro(token, options){
        const url = window.ENV.INBOX_API+'/v1/modules/cadastro/listar/processos';
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async updateManyCadastro(token , options){
        const url = window.ENV.INBOX_API+'/v1/modules/cadastro/update/processos';
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async clearDuplicates(token, options){
        const url = window.ENV.INBOX_API+'/v1/modules/cadastro/clear/processos';
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async limparCadastro(token, options){
        const url = window.ENV.INBOX_API+'/v1/modules/cadastro/limpar/processos';
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async importarCadastroXLSX(token, options, fileData={}){
        const formData = new FormData();
        const blob = new Blob([fileData], { type: 'application/octet-stream' });
        formData.append('file', blob, "file.do");
        formData.append('options', JSON.stringify(options));

        const url = window.ENV.INBOX_API+'/v1/modules/cadastro/importar/processos';
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    async sortVagasEscolas(token, options){
        const url = window.ENV.INBOX_API+'/v1/modules/escola/sort/processos';
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            const data = await response.json();
            return {
                error:data,
                status:response.status,
                statusText:response.statusText
            }
        }
    }

    clear(event){
        if(!event) this.removeAllListeners()
        else this.removeAllListeners(event)
    }

}

export const moduleControl = new Module()
export default Module