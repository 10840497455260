import {useFormContext} from "react-hook-form";
import {useCallback, useEffect} from "react";
import {useDropzone} from "react-dropzone";
import {useTranslation} from "react-i18next";

const RHFInputDocumento = (props)=>{
    const {t} = useTranslation();

    const {name, label = name, maxFiles = 1, accept, documento={}} = props;

    const { register, unregister, setValue } = useFormContext();

    //const files = watch(name)

    const onDrop = useCallback(
        (droppedFiles) => {
            setValue(name, droppedFiles, {shouldValidate:true})
        },[setValue, name]
    )

    const {getRootProps, getInputProps, acceptedFiles, open} = useDropzone({
        maxFiles,
        noClick: true,
        noKeyboard: true,
        onDrop,
        accept
    })

    const files = acceptedFiles.map(file => (
        <span className="font-weight-bold pt-1" key={file.path}>
            {file.path} - {file.size} bytes
        </span>
    ));

    useEffect(()=>{
        register(name)
        return ()=> {
            unregister(name)
        }
    },[register, unregister, name])

    return (
        <>
            <div {...getRootProps()}>
                <input {...props} id={name} {...getInputProps()} />
                <div className="card card-body p-2 bg-light mr-2" style={{maxWidth:'400px', minWidth:'400px', minHeight:'110px'}}>
                    <div className="media">
                        <div className="media-body">
                            <div className="media-title font-weight-semibold font-size-lg">
                                {label} {(documento.validar) ? <span className="text-danger">{' ('+t('requerido')+')'}</span>:''}
                            </div>
                            <span className="text-success">{documento.pontos+' '+t('pontos')}</span>
                        </div>
                        <div className="ml-3 mt-1">
                            <button type="button" onClick={open} className="btn btn-secondary btn-labeled btn-labeled-left">
                                <b><i className="icon-upload"></i></b>
                                {t('inserir')}
                            </button>
                        </div>
                    </div>
                    <div className="mt-2">
                        {files}
                    </div>
                </div>
            </div>
        </>
    )

}

export default RHFInputDocumento