import 'rsuite/dist/rsuite.min.css';

import 'react-notifications-component/dist/theme.css'
import 'tabulator-tables/dist/css/tabulator.min.css';
import 'tabulator-tables/dist/css/tabulator_simple.min.css';

import 'react-dual-listbox/lib/react-dual-listbox.css';

import "primereact/resources/themes/lara-light-blue/theme.css";
import "primeicons/primeicons.css";

import './styles/bootstrap.css';
import './styles/bootstrap_limitless.css';
import './styles/colors.css';
import './styles/components.css';
import './styles/layout.css';
import './styles/loading.bars.css';
import './styles/loading.css';
import './styles/icons/icomoon/styles.min.css';
import './styles/globals.css';
import './styles/tabulator.xs.css';

import {ControlContextProvider} from './context/ControlContext'
import {AuthContextProvider} from './context/AuthContext'
import {ConfigContextProvider} from "./context/ConfigContext";
import {ServicoContextProvider} from "./context/ServicoContext";

import Content from "./components/Content";
import {BrowserRouter as Router} from "react-router-dom";
import Menu from "./components/Menu";
import Notify from "./components/Notify";


const App = props => {
    return (
        <ConfigContextProvider>
            <ControlContextProvider>
                <AuthContextProvider>
                    <ServicoContextProvider>
                        <Router>
                            <Notify />
                            <Menu />
                            <Content />
                        </Router>
                    </ServicoContextProvider>
                </AuthContextProvider>
            </ControlContextProvider>
        </ConfigContextProvider>
    )
}
export default App