import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import { Controller } from "react-hook-form";


const RHFMuiSelect = ({name, label, control, defaultValue, options, width = '100%'}) => {
    const labelId = `${name}-label`;
    const [optionValues, setOptionValues] = useState([]);

    useEffect(()=>{
        setOptionValues(options);
    },[options])

    return (
        <Controller
            render={
                ({field: { onChange, onBlur, value, ref }}) => {
                    return (
                        <FormControl sx={{ m: 0, width }} size="small">
                            <InputLabel id={labelId}>{label}</InputLabel>
                            <Select
                                labelId={labelId}
                                label={label}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}>
                                {optionValues.map((option) => {
                                    if(!option.value || option.value.trim() === ''){
                                        return (<MenuItem key={option.value} value={option.value}><em>{option.name}</em></MenuItem>)
                                    }else{
                                        return (<MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>)
                                    }
                                })}
                            </Select>
                        </FormControl>
                    )
                }
            }
            name={name}
            control={control}
            onChange={() => {}}
            defaultValue={defaultValue}
        />
    );
};
export default RHFMuiSelect;