import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import { InputMask } from 'primereact/inputmask';
import { Controller } from "react-hook-form";

const RHFMask = forwardRef((props, ref) => {

    useImperativeHandle(
        ref,
        () => ({

        }),
    )

    const { name, control, placeholder, mask, defaultValue="" } = props;

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}

            render={({ field, fieldState }) => (
                <>
                    <InputMask
                        id={field.name}
                        value={field.value || ''}
                        onChange={(e) => field.onChange(e.target.value)}
                        ref={field.ref}
                        mask={mask}
                        placeholder={placeholder}
                        style={{width:"100%"}}
                    />
                </>
            )}
        />
    );
})

export default RHFMask;
