import React, {useEffect, useState} from "react";
import DualListBox from 'react-dual-listbox';
import { Controller } from "react-hook-form";

const RHFListDouble = ({name, control, defaultValue, options, availableHeader='Available', selectedHeader='Selected'}) => {

    const [selected, setSelected] = useState([]);

    return (
        <Controller
            render={
                ({field}) => {
                    return <DualListBox
                        options={options}
                        selected={selected}
                        onChange={(newValue) => {
                            field.onChange(newValue);
                            setSelected(newValue)
                        }}
                        preserveSelectOrder
                        showOrderButtons
                        showHeaderLabels
                        lang={{
                            availableFilterHeader: 'Filter available',
                            availableHeader,
                            filterPlaceholder: 'Search...',
                            moveAllLeft: 'Move all to available',
                            moveAllRight: 'Move all to selected',
                            moveLeft: 'Move to available',
                            moveRight: 'Move to selected',
                            moveBottom: 'Rearrange to bottom',
                            moveDown: 'Rearrange down',
                            moveUp: 'Rearrange up',
                            moveTop: 'Rearrange to top',
                            noAvailableOptions: 'No available options',
                            noSelectedOptions: 'No selected options',
                            requiredError: 'Please select at least one option.',
                            selectedFilterHeader: 'Filter selected',
                            selectedHeader,
                        }}
                        icons={{
                            moveToAvailable: <span className="icon-arrow-left5" />,
                            moveAllToAvailable: <span className="icon-backward2" />,
                            moveToSelected: <span className="icon-arrow-right5" />,
                            moveAllToSelected: <span className="icon-forward3" />,
                            moveDown: <span className="icon-arrow-down5" />,
                            moveUp: <span className="icon-arrow-up5" />,
                            moveTop: <span className="icon-arrow-up12" />,
                            moveBottom: <span className="icon-arrow-down12" />,
                        }}
                    />
                }
            }
            control={control}
            name={name}
            defaultValue={defaultValue}
        />
    );
};
export default RHFListDouble;