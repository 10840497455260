import React, {useEffect, useState, forwardRef} from "react";
import {useTranslation} from "react-i18next";
import moment from 'moment';
import {moduleControl} from "../control/Module";

const ComprovanteFase1 = forwardRef((props, ref) => {
    const {servico, vaga, registros, cadastro, mail} = props;

    const {t} = useTranslation();
    const [pontos, setPontos] = useState(0)

    useEffect(()=>{
        const mapByTipoDocumento = {}
        for(const [,registro] of registros.entries()){
            if(registro.tipo_documento){
                mapByTipoDocumento[registro.tipo_documento] = registro;
            }
        }

        let previa = 0;
        if(servico._module_documentos && servico._module_documentos[vaga.codigo]){
            const documentos = servico._module_documentos[vaga.codigo];
            for(const docID of Object.keys(documentos)){
                if(mapByTipoDocumento[docID]){
                    previa += documentos[docID].pontos;
                }
            }
        }
        setPontos(previa)

        const enviarEmail = async ()=>{
            await moduleControl.enviarEmailWarning({
                to:cadastro.email,
                subject:t('info_avisar_inscricao',{processo:servico._module_options.codigo, vaga: vaga.codigo}),
                body:t('numero_inscricao')+': '+cadastro.uuid
            });
        }
        if(mail){
            enviarEmail().then((rs)=>{}).catch(err=>{
                console.error(err);
            });
        }
    },[registros, servico._module_documentos, vaga.codigo])

    return (
        <div ref={ref}>
            <div className="card-body">
                <div className="d-lg-flex flex-lg-wrap">
                    <div className="mb-4 mb-lg-2">
                        <span className="text-muted">{t('cadastro')}</span>
                        <ul className="list list-unstyled mb-0">
                            <li><h5 className="my-2">{cadastro.nome}</h5></li>
                            <li><span className="font-weight-semibold">{cadastro.cpf}</span></li>
                            <li>{cadastro.email}</li>
                            <li>{cadastro.dtnasc}</li>
                        </ul>
                    </div>

                    <div className="mb-2 ml-auto">
                        <span className="text-muted">{t('processo')}</span>
                        <div className="d-flex flex-wrap wmin-lg-400">
                            <ul className="list list-unstyled mb-0">
                                <li><h5 className="my-2">{t('cadastro')}</h5></li>
                                <li>{t('descricao')}</li>
                                <li>{t('bairro')}</li>
                                <li>{t('cidade')}</li>
                                <li>{t('uf')}</li>
                            </ul>

                            <ul className="list list-unstyled text-right mb-0 ml-auto">
                                <li><h5 className="font-weight-semibold my-2">{vaga.codigo}</h5></li>
                                <li><span>{vaga.descricao}</span></li>
                                <li><span>{vaga.bairro}</span></li>
                                <li><span>{vaga.cidade}</span></li>
                                <li><span>{vaga.uf}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-xs">
                    <thead>
                    <tr>
                        <th>{t('documentos')}</th>
                        <th>{t('pontos_previstos')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {registros.map((registro)=>{
                        return (
                            <tr key={registro._id}>
                                <td>
                                    <h6 className="mb-0">{registro.tipo_documento}</h6>
                                    <span className="text-muted">{registro._filename}</span>
                                </td>
                                <td>
                                                <span className="font-weight-semibold">{
                                                    (servico._module_documentos && servico._module_documentos[vaga.codigo] &&
                                                        servico._module_documentos[vaga.codigo][registro.tipo_documento]) ?
                                                        servico._module_documentos[vaga.codigo][registro.tipo_documento].pontos:'--'
                                                }</span></td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <div className="card-body">
                <div className="d-lg-flex flex-lg-wrap">
                    <div className="pt-2 mb-3">
                        <h6 className="my-2">{t('numero_inscricao')}</h6>
                        <h6 className="my-2 font-weight-bold">{cadastro.uuid}</h6>
                        <h6 className="my-2"><span className="font-weight-bold">Data: </span>
                            {(cadastro.data) ? moment(cadastro.data).format('DD/MM/YYYY HH:mm'):''}</h6>
                        <h6>* {t('info_pontuacao_prevista')}</h6>
                    </div>

                    <div className="pt-2 mb-3 wmin-lg-400 ml-auto">
                        <div className="table-responsive">
                            <table className="table">
                                <tbody>
                                <tr>
                                    <th>Total:</th>
                                    <td className="text-right text-primary"><h5
                                        className="font-weight-semibold">{pontos}</h5></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default ComprovanteFase1