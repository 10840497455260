import {createContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const ControlContext = createContext({});

export const ControlContextProvider = ({clientid, children}) => {

    const {t} = useTranslation();

    const [leftSidebar, setLeftSidebar] = useState();
    const [rightSidebar, setRightSidebar] = useState();

    useEffect(()=>{

    },[])


    const context = {
        leftSidebar,
        setLeftSidebar,
        rightSidebar,
        setRightSidebar,
    }

    return (
        <ControlContext.Provider value={context}>{children}</ControlContext.Provider>
    )
}

export default ControlContext