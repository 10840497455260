import {useContext} from "react";
import AuthContext from "../context/AuthContext";

export default function Protected({id, user,children}){
    const {security} = useContext(AuthContext)
    if(!user || !security || !security[id]){
        return (<>Unauthorized</>)
    }else{
        return (
            <>
                {children}
            </>
        )
    }

}