import React, {useEffect, forwardRef, useImperativeHandle} from "react";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";

const MaskEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const RHFEmail = forwardRef( (props,ref) => {
    const {t} = useTranslation();
    const {name, showError=true, className="form-control form-control-sm "} = props;

    const {register, watch, setError, clearErrors, formState: { errors }} = useFormContext();
    const watchField = watch(name);

    useImperativeHandle(
        ref,
        () => ({})
    )

    const validate = ()=>{
        return MaskEmail.test(watchField)
    }

    useEffect(()=>{
        if(!validate()){
            setError(name, { type:"manual", message:t('info_campo_invalido',{campo:t(name)}) })
        }else{
            clearErrors(name)
        }
    },[])

    useEffect(()=>{
        if(!validate()){
            setError(name, { type:"manual", message:t('info_campo_invalido',{campo:t(name)}) })
        }else{
            clearErrors(name)
        }
    },[watchField])

    return (
        <input type="text" className={className+((errors[name] && showError)?" border-danger":"")}
               placeholder="" {...register(name)} />
    );
});
export default RHFEmail;