import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import RHFSelect from "../forms/RHFSelect";
import {useTranslation} from "react-i18next";
import RHFInputNumber from "../forms/RHFInputNumber";
import RHFMask from "../forms/RHFMask";
import ServicoContext from "../../context/ServicoContext";
import RHFEmail from "../forms/RHFEmail";
import RHFCpf from "../forms/RHFCpf";
import {useFormContext} from "react-hook-form";

const FormField = forwardRef( (props, ref) => {

    const {t} = useTranslation();

    const {getStateCollection} = useContext(ServicoContext);

    const {index, tableItem, handleSelectChange, inputRef} = props;

    const {
        register,
        control,
        watch,
        setError,
        clearErrors,
        formState: { errors },
        setFocus
    } = useFormContext();

    useImperativeHandle(
        ref,
        () => ({

        }),
    )

    const renderField = (item, inputRef, index) => {

        if (item._type === 'list') {
            return (
                <div key={'form-field-' + index} className="form-group mb-0">
                    <label className="font-weight-bold">{item.title}</label>
                    <div className="form-group mb-0 mt-1">
                        <RHFSelect
                            ref={inputRef}
                            name={item.field}
                            placeholder={t('selecione')}
                            control={control}
                            handleChange={handleSelectChange}
                            isClearable={!!(item._empty)}
                            defaultValue={(item._values[0] && !item._empty) ? {value: item._values[0], label: item._values[0]} : ""}
                            options={(item._values) ? item._values.map((value) => {
                                return {label: value, value}
                            }) : []}
                        />
                    </div>
                    <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors[item.field]) ? errors[item.field].message : ""}</div>
                </div>
            )
        } else if (item._type === 'date') {
            return (
                <div key={'form-field-' + index} className="form-group mb-0">
                    <label className="font-weight-bold">{item.title}</label>
                    <div className="form-group mb-0 mt-0">
                        <input ref={inputRef} type="date" {...register(item.field)} className={"form-control form-control-sm" + ((errors[item.field]) ? " border-danger" : "")}/>
                    </div>
                    <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors[item.field]) ? errors[item.field].message : ""}</div>
                </div>
            )
        } else if (item._type === 'number') {
            return (
                <div key={'form-field-' + index} className="form-group mb-0">
                    <label className="font-weight-bold">{item.title}</label>
                    <div className="form-group mb-0 mt-1">
                        <RHFInputNumber
                            ref={inputRef}
                            name={item.field}
                            control={control}
                            defaultValue={0}
                        />
                    </div>
                    <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors[item.field]) ? errors[item.field].message : ""}</div>
                </div>
            )
        } else if (item._type === 'phone') {
            return (
                <div key={'form-field-' + index} className="form-group mb-0">
                    <label className="font-weight-bold">{item.title}</label>
                    <div className="form-group mb-0 mt-1">
                        <RHFMask
                            ref={inputRef}
                            name={item.field}
                            mask="(99) 99999-9999"
                            control={control}
                            defaultValue={""}
                        />
                    </div>
                    <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors[item.field]) ? errors[item.field].message : ""}</div>
                </div>
            )
        } else if (item._type === 'cpf') {
            return (
                <div key={'form-field-' + index} className="form-group mb-0">
                    <label className="font-weight-bold">{item.title}</label>
                    <div className="form-group mb-0 mt-1">
                        <RHFCpf
                            ref={inputRef}
                            name={item.field}
                            defaultValue={""}
                        />
                    </div>
                    <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors[item.field]) ? errors[item.field].message : ""}</div>
                </div>
            )
        } else if (item._type === 'email') {
            return (
                <div key={'form-field-' + index} className="form-group mb-0">
                    <label className="font-weight-bold">{item.title}</label>
                    <div className="form-group mb-0 mt-1">
                        <RHFEmail
                            ref={inputRef}
                            name={item.field}
                            defaultValue={""}
                        />
                    </div>
                    <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors[item.field]) ? errors[item.field].message : ""}</div>
                </div>
            )
        } else if (item._type === 'datetime') {
            return (
                <div key={'form-field-' + index} className="form-group mb-0">
                    <label className="font-weight-bold">{item.title}</label>
                    <div className="form-group mb-0 mt-1">
                        <input ref={inputRef} type="datetime-local" {...register(item.field)}
                               className={"form-control form-control-sm" + ((errors[item.field]) ? " border-danger" : "")}/>
                    </div>
                    <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors[item.field]) ? errors[item.field].message : ""}</div>
                </div>
            )
        } else if (item._type === 'reference') {

            const collectionList = getStateCollection(item._crud);

            const referenceValues = collectionList.map((entry) => {
                return {label: entry[item._crud_label], value: entry[item._crud_label]}
            })

            const defaultValue = (referenceValues[0] && !item._empty) ? referenceValues[0] : null

            return (
                <div key={'form-field-' + index} className="form-group mb-0">
                    <label className="font-weight-bold">{item.title}</label>
                    <div className="form-group mb-0">
                        <RHFSelect
                            ref={inputRef}
                            name={item.field}
                            placeholder={t('selecione')}
                            control={control}
                            isClearable={!!(item._empty)}
                            defaultValue={defaultValue}
                            options={referenceValues}
                            handleChange={handleSelectChange}
                        />
                    </div>
                    <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors[item.field]) ? errors[item.field].message : ""}</div>
                </div>
            )
        } else {
            return (
                <div key={'form-field-' + index} className="form-group mb-0">
                    <label className="font-weight-bold">{item.title}</label>
                    <input ref={inputRef} type="text" placeholder="" {...register(item.field)}
                           className={"form-control form-control-sm" + ((errors[item.field]) ? " border-danger" : "")}/>
                    <div style={{height: "15px"}} className="font-size-xs text-muted text-right">{(errors[item.field]) ? errors[item.field].message : ""}</div>
                </div>
            )
        }
    }

    return renderField(tableItem, inputRef, index)

})

export default FormField