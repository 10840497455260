import React, {useEffect, useState, forwardRef} from "react";
import {useTranslation} from "react-i18next";
import moment from 'moment';
import {moduleControl} from "../control/Module";

const ComprovanteFase2 = forwardRef((props, ref) => {
    const {inscricao, vaga, escolas, servico} = props;

    const {t} = useTranslation();

    useEffect(()=>{
        const enviarEmail = async ()=>{
            const opts = {
                to:inscricao.email,
                subject:t('info_avisar_indicacao',{processo:servico._module_options.codigo, vaga: vaga.codigo, numero:inscricao.uuid}),
                body:[
                    t('numero_inscricao')+': <b>'+inscricao.uuid+'</b>',
                    t('escola_atual')+': <b>'+inscricao.escola_atual+'</b>',
                    t('escola_atual')+': <b>'+inscricao.cargo_atual+'</b>',
                    '<b>'+t('escolas_selecionadas')+'</b>',
                    ...escolas.map((item,i)=>(i+1)+'º - '+item)
                ].join('<br>')
            }
            await moduleControl.enviarEmailWarning(opts);
        }
        enviarEmail().then((rs)=>{}).catch(err=>{
            console.error(err);
        });
    },[inscricao, escolas, vaga.codigo])

    return (
        <div ref={ref}>
            <div className="card-body">
                <div className="d-lg-flex flex-lg-wrap">
                    <div className="mb-4 mb-lg-2">
                        <span className="text-muted">{t('cadastro')}</span>
                        <ul className="list list-unstyled mb-0">
                            <li><h5 className="my-2">{inscricao.nome}</h5></li>
                            <li><span className="font-weight-semibold">{inscricao.cpf}</span></li>
                            <li>{inscricao.email}</li>
                            <li>{inscricao.dtnasc}</li>
                        </ul>
                    </div>

                    <div className="mb-2 ml-auto">
                        <span className="text-muted">{t('processo')}</span>
                        <div className="d-flex flex-wrap wmin-lg-400">
                            <ul className="list list-unstyled mb-0">
                                <li><h5 className="my-2">{t('cadastro')}</h5></li>
                                <li>{t('descricao')}</li>
                                <li>{t('bairro')}</li>
                                <li>{t('cidade')}</li>
                                <li>{t('uf')}</li>
                            </ul>

                            <ul className="list list-unstyled text-right mb-0 ml-auto">
                                <li><h5 className="font-weight-semibold my-2">{vaga.codigo}</h5></li>
                                <li><span>{vaga.descricao}</span></li>
                                <li><span>{vaga.bairro}</span></li>
                                <li><span>{vaga.cidade}</span></li>
                                <li><span>{vaga.uf}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="d-lg-flex flex-lg-wrap">
                    <div className="pt-2 mb-3">
                        <div className="my-2">{t('escola_atual')}: <span className="font-weight-bold">{inscricao.escola_atual}</span></div>
                        <div className="my-2">{t('cargo_atual')}: <span className="font-weight-bold">{inscricao.cargo_atual}</span></div>
                        <div className="my-2 font-weight-bold">{t('escolas_selecionadas')}:</div>
                        {escolas.map((item, i)=><div key={item} className="font-size-xs">{(i+1)+'º - '+item}</div>)}
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="d-lg-flex flex-lg-wrap">
                    <div className="pt-2 mb-3">
                        <h6 className="my-2">{t('numero_inscricao')}</h6>
                        <h6 className="my-2 font-weight-bold">{inscricao.uuid}</h6>
                        <h6 className="my-2"><span className="font-weight-bold">Data: </span> {moment().format('DD/MM/YYYY HH:mm')}</h6>
                    </div>
                </div>
            </div>

        </div>
    )
})

export default ComprovanteFase2