import {createContext, useEffect, useState} from "react";
import configJSON from '../config/config.json';
import {authControl} from "../control/Auth";
import {moduleControl} from "../control/Module";
import storage from "../util/storage";

const AuthContext = createContext({
    user: null,
    signIn: ()=>{},
    signOut: ()=>{}
});

export const AuthContextProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [security, setSecurity] = useState(null);
    const [verifyJob, setVerifyJob] = useState(null);

    useEffect(()=>{
        const userStorage = storage.get('local','user')
        const logged = authControl.init(userStorage);
        if(logged){
            authControl.validate(userStorage.token).then((rs)=>{
                if(rs.error){
                    storage.remove('local','user')
                    setUser(null);
                }else if(rs.user){
                    storage.set('local','user', rs)
                    setUser(rs);
                }else{
                    storage.remove('local','user')
                    setUser(null);
                }
            }).catch(err=>{
                console.error(err);
                setUser(null);
            })
        }
        authControl.on('signIn',(user)=>{
            storage.set('local','user', user)
            setUser(user)
        })
        authControl.on('signOut',(rs)=>{
            storage.remove('local','user')
            setUser(null)
            setSecurity(null)
        })
        return ()=> {
            authControl.clear();
        }
    },[])

    useEffect(()=>{
        if(user){
            moduleControl.security(configJSON.id, user.token, user.userid).then((rs)=>{
                setSecurity(rs);
            }).catch(err=>{
                console.error(err);
            })
            if(verifyJob) clearInterval(verifyJob)
            setVerifyJob(null);
            const interval = setInterval(()=>{
                //console.log('VerifyJob',moment().format('DD/MM/YYYY HH:mm:ss'))
                const userStorage = storage.get('local','user');
                if(!userStorage){
                    authControl.signOut(user).then(()=>{
                        clearInterval(interval);
                        setVerifyJob(null);
                    }).catch(err=>{
                        console.error(err);
                        clearInterval(interval);
                        setVerifyJob(null);
                    })
                }
            },5000)
            setVerifyJob(interval);
        }else{
            clearInterval(verifyJob);
            setVerifyJob(null);
            setSecurity(null);
        }
    },[user])

    const signIn = async (opts)=>{
        return await authControl.signIn(opts)
    }
    const signOut = async (opts)=>{
        return await authControl.signOut(opts)
    }
    const sleep = (ms)=>{
        return new Promise((resolve, reject) => {
            setTimeout(()=>{
                resolve();
            },ms)
        });
    }
    const context = {
        user,
        security,
        signIn,
        signOut,
        sleep
    }

    return (
        <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
    )
}

export default AuthContext