import React, {useEffect, useRef, useState, useContext} from "react";
import {useTranslation} from "react-i18next";

import {authControl} from "../control/Auth";
import AuthContext from "../context/AuthContext";

import ProcessoBox from "./ProcessoBox";
import ModalNovoProcesso from "./ModalNovoProcesso";
import ServicoContext from "../context/ServicoContext";

const ProcessosContent = props => {
    const {t} = useTranslation();
    const {user} = useContext(AuthContext);
    const {servicos} = useContext(ServicoContext);

    const [adm, setAdm] = useState(false);
    useEffect(()=>{
        if(user){
            authControl.adm(user.token).then((rs)=>{
                if(rs.adm) setAdm(true);
                else setAdm(false);
            }).catch(err=>{
                console.error(err);
                setAdm(false);
            })
        }else{
            setAdm(false);
        }
    },[user])

    const [filtered, setFiltered] = useState([])
    const [search, setSearch] = useState('');

    useEffect(()=>{
        const filter = servicos.filter((servico)=>{
            return (
                search.trim() === '' ||
                (servico.nome && servico.nome.toLowerCase().includes(search.toLowerCase()))
            );
        })
        setFiltered(filter);
    },[servicos, search])

    /* BOX PROCESSO*/
    const boxRefs = useRef({});

    /* MODAL NOVO PROCESSO */
    const [novoProcessoModalShow, setNovoProcessoModalShow] = useState(false);
    const [editProcessoServico, setEditProcessoServico] = useState(null);
    const novoProcesso = (servico)=>{
        if(servico) setEditProcessoServico(servico);
        else setEditProcessoServico(null);
        setNovoProcessoModalShow(true);
    }
    const novoProcessoModalClose = ()=>{
        setNovoProcessoModalShow(false)
    }
    const handleNovoProcessoClose = ()=>{}
    const handleNovoProcessoEntered = ()=>{}

    return (
        <div className="mt-2">
            <div className="card" style={{minWidth:"315px"}}>
                <div className="card-header bg-light header-elements-sm-inline" style={{padding:'5px'}}>
                    {(adm) ?
                        <button onClick={(event)=>novoProcesso()} type="button" className="btn btn-secondary btn-labeled btn-labeled-left">
                            <b><i className="icon-plus2"></i></b>
                            {t('novo_processo')}
                        </button>
                        :
                        <div></div>
                    }
                    <div className="header-elements">
                        <div className="form-group form-group-feedback form-group-feedback-right">
                            <input type="search" className="form-control form-control-lg" placeholder={t('pesquisar')}
                                   onChange={(e)=>{setSearch(e.target.value)}}
                            />
                            <div className="form-control-feedback form-control-feedback-lg">
                                <i className="icon-search4 text-muted"></i>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (filtered.length === 0) ?
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12 text-center"><span className="text-muted">{t('msg_nenhum_processo_encontrado')}</span></div>
                            </div>
                        </div>
                        :
                        <div className="card-body" style={{padding:0,margin:'10px 10px 0 0'}}>
                            <div className="row">
                                <div className="col-md-12 p-0">
                                    {filtered.map(servico=>(<ProcessoBox key={servico._id} servico={servico} ref={el => boxRefs.current[servico._id] = el} />))}
                                </div>
                            </div>
                        </div>
                }
            </div>

            <ModalNovoProcesso
                servico={editProcessoServico}
                show={novoProcessoModalShow}
                close={novoProcessoModalClose}
                onClose={handleNovoProcessoClose}
                onOpen={handleNovoProcessoEntered} />

        </div>
    )
}

export default ProcessosContent