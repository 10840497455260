import {createContext, useEffect, useState, useContext} from "react";
import AuthContext from "./AuthContext";
import {moduleControl} from "../control/Module";
import {orderByKey} from "../control/Util"

const ServicoContext = createContext({
    servicos:[]
});

export const ServicoContextProvider = ({children}) => {

    const {user} = useContext(AuthContext)
    const [servicos, setServicos] = useState([]);
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null);

    const [c_professores, setC_professores] = useState([]);
    const [c_cargos, setC_cargos] = useState([]);
    const [c_escolas, setC_escolas] = useState([]);
    const [c_stateMap, setC_stateMap] = useState({})

    const [c_vagas, setC_vagas] = useState({})
    const [cm_vagas, setCM_vagas] = useState({})

    const stateMap = {
        professores: [c_professores, setC_professores],
        cargos: [c_cargos, setC_cargos],
        escolas: [c_escolas, setC_escolas]
    };

    const setCadastroState = (key, value)=>{
        if (stateMap[key]) {
            const [, setter] = stateMap[key];
            setter(value);
        } else {
            console.error(`State "${key}" não encontrado.`);
        }
    }

    /**
     * Get id state
     * @param id {string}
     * @returns {*}
     */
    const getStateCollection = (id)=>{
        if(id.startsWith("vagas.")){
            return c_vagas[id]
        }else{
            return stateMap[id][0]
        }
    }

    const getStateMap = (id)=>{
        if(id.startsWith("vagas.")){
            return cm_vagas[id]
        }else{
            return c_stateMap[id];
        }
    }

    useEffect(()=>{
        const listarServicos = async ()=>{
            let list = [];
            try{
                if(user){
                    list = await moduleControl.listarServicos(user.token)
                }else{
                    list = await moduleControl.listarServicos()
                }
            }catch (err){
                setError(err);
            }
            const sortedList = orderByKey(list,'nome')

            setServicos(sortedList.filter((servico)=>{
                return !(servico._module_options && servico._module_options.cadastro);
            }));
            //console.log('Atualizando Lista de Serviços', sortedList);
        }

        const load = async ()=>{
            setLoading(true);
            await listarServicos();
            setLoading(false);
        }
        load().catch(err=>{console.error(err)})
        moduleControl.clear();
        moduleControl.on('onInsert',(rs)=>{
            //console.log('onInsert')
            setLoading(true);
            listarServicos().then(r => {setLoading(false);});
        })
        moduleControl.on('onDelete',(rs)=>{
            //console.log('onDelete')
            setLoading(true);
            listarServicos().then(r => {setLoading(false)});
        })
        moduleControl.on('onEdit',(rs)=>{
            //console.log('onEdit')
            setLoading(true);
            listarServicos().then(r => {setLoading(false)});
        })
        return ()=> {
            moduleControl.clear();
        }
    },[user])

    const inserir = async (id, data)=>{
        return await moduleControl.novoServico(id, user.token, data)
    }
    const editar = async (id, data)=>{
        return await moduleControl.editarServico(id, user.token, data)
    }
    const remover = async (id)=>{
        return await moduleControl.removerServico(id, user.token)
    }



    /**
     * Listar Cadastro
     * @param id {string}
     * @param filter {{}}
     * @param sort {{}}
     * @returns {Promise<void>}
     */
    const listarCadastro = async (id, filter={}, sort={})=>{
        if(!id){
            throw 'No Id'
        }
        //console.log('Loading', id);
        let rs;
        if(!user){
            rs = await moduleControl.listarCadastroNoToken({id, filter, sort});
        }else{
            rs = await moduleControl.listarCadastro(user.token, {id, filter, sort});
        }
        if(!rs.error){
            if(id.startsWith("vagas.")){
                setC_vagas((current)=>{
                    return {...current, [id]: rs.list}
                })
                setCM_vagas((current)=>{
                    return {...current, [id]: rs.map}
                })
            }else{
                setCadastroState(id, rs.list);
                setC_stateMap((current)=>{
                    return {...current, [id]: rs.map}
                })
            }
            return rs.list;
        }else{
            throw rs;
        }
    }

    const salvarCadastro = async (id, cadastro, entity) => {
        if(!id){
            throw 'No ID'
        }
        const rs = await moduleControl.salvarCadastro(user.token, {id, entity, cadastro});
        if(!rs.error){
            await listarCadastro(id);
            return rs;
        }else{
            throw rs;
        }
    }

    const removerCadastro = async (id, _ids) => {
        if(!id){
            throw 'No ID'
        }
        const rs = await moduleControl.removerCadastro(user.token, {id, _ids});
        if(!rs.error){
            await listarCadastro(id);
            return rs;
        }else{
            throw rs;
        }
    }

    const updateManyCadastro = async (id, map)=>{
        if(!map){
            throw 'No Update Map'
        }
        const rs = await moduleControl.updateManyCadastro(user.token, {
            id, map
        })
        if(!rs.error){
            await listarCadastro(id);
            return rs;
        }else{
            throw rs;
        }
    }

    const clearDuplicates = async (id, pk)=>{
        if(!id){
            throw 'No ID'
        }
        const rs = await moduleControl.clearDuplicates(user.token, {
            id, pk
        });
        if(!rs.error){
            await listarCadastro(id);
            return rs;
        }else{
            throw rs;
        }
    }

    const limparCadastro = async (id) =>{
        if(!id){
            throw 'No ID'
        }
        const rs = await moduleControl.limparCadastro(user.token, {id});
        if(!rs.error){
            await listarCadastro(id);
            return rs;
        }else{
            throw rs;
        }
    }

    /**
     * Importar XLSX
     * @param id {string}
     * @param cadastro {{}}
     * @param fileData {{}} FileData
     * @param sheetName {string}
     * @param headerRowIndex {number} Header Index
     * @param campos {{}} Campos
     * @param update {[]} update key fields
     * @returns {Promise<void>}
     */
    const importarCadastroXLSX = async (id, cadastro, fileData, sheetName="", headerRowIndex, campos={}, update=[])=>{
        if(!id){
            throw 'Incorrect Params'
        }
        const rs = await moduleControl.importarCadastroXLSX(user.token, {
            id,
            cadastro,
            sheetName,
            headerRowIndex,
            campos,
            update
        }, fileData)

        if(!rs.error){
            await listarCadastro(id);
            return rs;
        }else{
            throw rs;
        }
    }

    const sortVagasEscolas = async (servico) =>{
        if(!servico){
            throw 'No ID Servico'
        }
        const rs = await moduleControl.sortVagasEscolas(user.token, {servico});
        if(!rs.error){
            return rs;
        }else{
            throw rs;
        }
    }

    const context = {
        error,
        loading,
        servicos,
        remover,
        inserir,
        editar,

        getStateCollection,
        getStateMap,

        salvarCadastro,
        removerCadastro,
        listarCadastro,
        updateManyCadastro,
        clearDuplicates,
        limparCadastro,
        importarCadastroXLSX,

        sortVagasEscolas
    }

    return (
        <ServicoContext.Provider value={context}>{children}</ServicoContext.Provider>
    )
}

export default ServicoContext