import {useContext} from "react";

import {Route, Routes} from "react-router-dom";
import Home from "./views/Home";
import NotFound from "./views/NotFound";
import AuthContext from "../context/AuthContext";
import ControlContext from "../context/ControlContext";

import Protected from "./Protected";

import Inscritos from "./views/Inscritos";
import Cancelar from "./views/Cancelar";
import Validar from "./views/Validar";
import Cadastro from "./views/Cadastro";
import LeftSidebar from "./util/LeftSidebar";
import RightSidebar from "./util/RightSidebar";
import Professores from "./views/Professores";
import Escolas from "./views/Escolas";
import Cargos from "./views/Cargos";
import Classificar from "./views/Classificar";


export default function Content(props){
    const {user} = useContext(AuthContext)

    const {leftSidebar, setLeftSidebar, setRightSidebar, rightSidebar} = useContext(ControlContext);

    return (
        <>
            <div className="d-flex flex-row" style={{
                backgroundColor:'#4d5c79',
                color:'#fff',
                textAlign:"left",
                padding:'5px 5px 5px 20px'
            }}>
                <div className="w-100 font-weight-bold font-size-lg"></div>
            </div>
            <div className="page-content p-0" style={{width:"100%",height:"100%",position:"fixed"}}>
                {(leftSidebar) ? <LeftSidebar menu={leftSidebar}/> : <></>}
                <div className="content-wrapper">
                    <div className="content">
                        <Routes>
                            <Route exact path="/" element={<Home/>} />
                            <Route path="/home" element={<Home/>} />
                            <Route path="/cancelar" element={<Cancelar/>} />

                            <Route path="/cadastro" element={<Protected user={user} id="cadastro"><Cadastro/></Protected>} />
                            <Route path="/professores" element={<Protected user={user} id="cadastro"><Professores/></Protected>} />
                            <Route path="/escolas" element={<Protected user={user} id="cadastro"><Escolas/></Protected>} />
                            <Route path="/cargos" element={<Protected user={user} id="cadastro"><Cargos/></Protected>} />

                            <Route path="/inscritos" element={<Protected user={user} id="inscritos"><Inscritos/></Protected>} />
                            <Route path="/validar" element={<Protected user={user} id="validar"><Validar/></Protected>} />
                            <Route path="/classificar" element={<Protected user={user} id="validar"><Classificar/></Protected>} />

                            <Route path="*" element={<NotFound/>} />
                        </Routes>
                    </div>
                </div>
                {(rightSidebar) ? <RightSidebar menu={rightSidebar}/> : <></>}
            </div>
        </>
    )
}