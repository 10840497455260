import React, {useEffect, useState, useContext} from "react";
import moment from 'moment';
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import { useForm, Controller } from "react-hook-form";
import {Checkbox} from "@mui/material";

import ServicoContext from "../context/ServicoContext";

import {notify} from "./Notify";
import Loader from "./Loader";
import RHFCheckbox from "./forms/RHFCheckbox";
import RHFSelect from "./forms/RHFSelect";

export default function ModalNovoProcesso(props){
    const {t} = useTranslation();
    const {inserir:inserirServico, editar:editarServico} = useContext(ServicoContext)

    const [showModal, setShowModal] = useState(false);
    const [servico, setServico] = useState(null);
    const [loader, setLoader] = useState({show:false, msg:''})

    const { register, control, handleSubmit, reset, setFocus, setValue, formState: { errors } } = useForm();

    useEffect(()=>{
        setShowModal(props.show);
        setServico(props.servico);
        //console.log('novo',props.show, props.servico);
        if(props.show && props.servico){
            if(servico._module_options.codigo) setValue('codigo', servico._module_options.codigo)
            if(servico.nome) setValue('inbox.nome', servico.nome)
            if(servico.descricao) setValue('inbox.descricao', servico.descricao)
            if(servico._module_options.validade) {
                const value = moment(servico._module_options.validade,'YYYYMMDDHHmm').format('YYYY-MM-DDTHH:mm')
                setValue('validade', value)
            }
            if(servico._module_options.fase) setValue('fase', {label:(servico._module_options.fase === 1) ? t('fase')+' 01: '+t('inscricao') : t('fase')+' 02: '+t('indicacao'), value:servico._module_options.fase})
            if(servico._module_options.portaria) setValue('portaria', servico._module_options.portaria)
            if(servico._module_options.multiplo) setValue('multiplo', servico._module_options.multiplo)
            if(servico._public) setValue('inbox._public', servico._public)
        }
    },[props.show, props.servico])

    const onSubmit = (data, e) => {
        const fields = {...data};

        if(!fields.codigo || fields.codigo.trim() === ''){
            notify('warning', t('processo'), t('info_campo_vazio',{campo:t('codigo_processo')}))
            return;
        }
        if(!fields.inbox.nome || fields.inbox.nome.trim() === ''){
            notify('warning', t('processo'), t('info_campo_vazio',{campo:t('nome')}))
            return;
        }
        if(fields.validade !== ''){
            fields.validade = moment(fields.validade).format('YYYYMMDDHHmmss')
        }
        fields.fase = (fields.fase) ? fields.fase.value : 1;

        setLoader({show:true, msg: t('salvando')})
        if(!servico){
            fields.campos = [
                {id:'vaga', titulo:t('cadastro'), tipo:'string', config:'', chave:true, mandatorio:false, sort:1},
                {id:'cpf', titulo:t('cpf'), tipo:'string', config:'', chave:true, mandatorio:false, sort:0},
                {id:'nome', titulo:t('nome'), tipo:'string', config:'', chave:false, mandatorio:false, sort:1},
                {id:'dtnasc', titulo:t('dtnasc'), tipo:'date', config:'DD/MM/YYYY', chave:false, mandatorio:false, sort:0},
                {id:'genero', titulo:t('genero'), tipo:'string', config:'', chave:false, mandatorio:false, sort:0},

                {id:'matricula', titulo:t('matricula'), tipo:'string', config:'', chave:false, mandatorio:false, sort:0},
                {id:'cargo_atual', titulo:t('cargo_atual'), tipo:'string', config:'', chave:false, mandatorio:false, sort:0},
                {id:'escola_atual', titulo:t('escola_atual'), tipo:'string', config:'', chave:false, mandatorio:false, sort:0},


                //{id:'endereco', titulo:t('endereco'), tipo:'string', config:'', chave:false, mandatorio:false, sort:0},
                //{id:'bairro', titulo:t('bairro'), tipo:'string', config:'', chave:false, mandatorio:false, sort:0},
                //{id:'cidade', titulo:t('cidade'), tipo:'string', config:'', chave:false, mandatorio:false, sort:0},
                //{id:'estado', titulo:t('estado'), tipo:'string', config:'', chave:false, mandatorio:false, sort:0},
                //{id:'cep', titulo:t('cep'), tipo:'string', config:'', chave:false, mandatorio:false, sort:0},
                {id:'tel_celular', titulo:t('tel_celular'), tipo:'string', config:'', chave:false, mandatorio:false, sort:0},
                //{id:'tel_recado', titulo:t('tel_recado'), tipo:'string', config:'', chave:false, mandatorio:false, sort:0},
                {id:'email', titulo:t('email'), tipo:'string', config:'', chave:false, mandatorio:false, sort:0},
                {id:'tipo_documento', titulo:t('documento'), tipo:'string', config:'', chave:false, mandatorio:false, sort:1},
                {id:'data', titulo:t('data'), tipo:'date', config:'', chave:false, mandatorio:false, sort:0},
                {id:'uuid', titulo:t('uuid'), tipo:'string', config:'', chave:false, mandatorio:false, sort:0},
                {id:'valid', titulo:t('valido'), tipo:'boolean', config:'', chave:false, mandatorio:false, sort:0},
                {id:'valid_date', titulo:t('data_validacao'), tipo:'date', config:'', chave:false, mandatorio:false, sort:0},
                {id:'valid_user', titulo:t('avaliador'), tipo:'string', config:'', chave:false, mandatorio:false, sort:0},
                {id:'pontos', titulo:t('pontos'), tipo:'number', config:'', chave:false, mandatorio:false, sort:0},
            ]
            inserirServico(fields.codigo, fields).then((rs)=>{
                if(rs.error && rs.error.code === -200) {
                    notify('warning', t('processo'), t('error_codigo_processo_existente'))
                }else{
                    reset()
                    props.close();
                }
                setLoader({show:false, msg:''})
            }).catch(err=>{
                console.error(err);
                notify('danger', t('processo'), t('error_problema_criar_processo'))
                reset()
                props.close();
                setLoader({show:false, msg: ''})
            })
        }else{
            editarServico(servico._id, fields).then((rs)=>{
                if(rs.error) {
                    notify('danger', t('processo'), t('error_problema_salvar_processo'))
                }else{
                    reset()
                    props.close();
                }
                setLoader({show:false, msg:''})
            }).catch(err=>{
                console.error(err);
                notify('danger', t('processo'), t('error_problema_salvar_processo'))
                reset()
                props.close();
                setLoader({show:false, msg:''})
            })
        }
    }

    const onEntered = ()=>{
        if(!servico) setFocus("codigo", { shouldSelect: true })
        else setFocus("inbox.nome", { shouldSelect: true })
        if(props.onOpen) props.onOpen()
    }
    const onExited = ()=>{
        reset();
        if(props.onClose) props.onClose();
    }

    return (
        <Modal
            show={showModal}
            onExited={onExited}
            onEntered={onEntered}
            animation={true}
            dialogClassName="modal-lg"
        >
            <Loader show={loader.show} msg={loader.msg} type="modal" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header>
                    <h6 className="font-weight-semibold">
                        <i className="icon-plus2 mr-2" />
                        {t('novo_processo')}
                    </h6>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card mb-1">
                                <div className="card-header bg-light p-2">
                                    <span className="card-title font-weight-semibold">{t('cadastro')}</span>
                                </div>
                                <div className="card-body p-2">
                                    <div className="form-group mb-2">
                                        <label>{t('codigo_processo')}</label>
                                        <input className="form-control form-control-sm" disabled={!!servico} type="text" placeholder="" {...register("codigo")} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>{t('nome')}</label>
                                        <input className="form-control form-control-sm" type="text" placeholder="" {...register("inbox.nome")} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>{t('descricao')}</label>
                                        <input className="form-control form-control-sm" type="text" placeholder="" {...register("inbox.descricao")} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>{t('validade_inscricoes')}</label>
                                        <input className="form-control form-control-sm" type="datetime-local" placeholder="" {...register("validade")} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>{t('fase')}</label>
                                        <RHFSelect
                                            name="fase"
                                            placeholder={t('selecione_a',{item:t('fase')})}
                                            control={control}
                                            isClearable={false}
                                            defaultValue={{label:t('inscricoes'), value:1}}
                                            options={[
                                                {label:t('fase')+' 01: '+t('inscricao'), value:1},
                                                {label:t('fase')+' 02: '+t('indicacao'), value:2}
                                            ]}
                                        />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>{t('portaria_legal')}</label>
                                        <input className="form-control form-control-sm" type="text" placeholder="" {...register("portaria")} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label>{t('multiplas_inscricoes')}</label>
                                        <RHFCheckbox control={control} name="multiplo" />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label className="text-primary">{t('publicar')}</label>
                                        <RHFCheckbox control={control} name="inbox._public" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit"  variant="primary">{t('salvar')}</Button>
                    <Button variant="light" onClick={props.close}>{t('fechar')}</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}