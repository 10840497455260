import React from "react";
import {Checkbox} from "@mui/material";
import { Controller } from "react-hook-form";


const RHFCheckbox = ({name, control, defaultValue}) => {
    return (
        <Controller
            render={
                ({field}) => {
                    return <Checkbox {...field} checked={!!field.value} />
                }
            }
            control={control}
            name={name}
            defaultValue={defaultValue}
        />
    );
};
export default RHFCheckbox;