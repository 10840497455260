import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import Loader from "./Loader";
import {moduleControl} from "../control/Module";
import AuthContext from "../context/AuthContext";

const Viewer = ({source,ext})=>{
    if(!source){
        return (<></>)
    }else{
        if(ext && ext === 'pdf'){
            return (
                <div style={{height: "92vh"}}>
                    <iframe src={"/pdfjs/web/viewer.html?file="+source+""}></iframe>
                </div>
            )
        }else if(ext && (ext === 'png' || ext === 'jpg' || ext === 'jpeg')){
            return (
                <div style={{height: "92vh"}}>
                    <img style={{maxHeight: "100%",maxWidth: "100%"}} src={source} alt="" />
                </div>
            )
        }
    }
}

const Arquivo = (props) => {
    const {t} = useTranslation();
    const {documento, servico} = props;

    const {user} = useContext(AuthContext);
    const [loader,setLoader] = useState({show:true,msg:t('carregando')})
    const [arquivo,setArquivo] = useState(null);
    const [ext,setExt] = useState(null);

    useEffect(()=>{
        setLoader({show:true,msg:t('carregando')})
        const downloadFile = async ()=>{
            if(servico && documento && documento._filename){
                if(documento._ext){
                    setExt(documento._ext);
                }else{
                    let ext = documento._filename.split('.').pop();
                    setExt(ext.toLowerCase())
                }
                const blob = await moduleControl.abrirDocumento(user.token, servico.tab, documento._id)
                if(!blob.error){
                    const data_url = URL.createObjectURL(blob);
                    setArquivo(data_url);
                }else{
                    setArquivo(null);
                }
            }else{
                setArquivo(null);
            }
        }
        downloadFile().then(()=>{
            setLoader({show:false,msg:''})
        }).catch(err=>{
            setLoader({show:false,msg:''})
            console.error(err);
        })
    },[documento])

    return (
        <>
            <Loader show={loader.show} msg={loader.msg} type="content" />
            <div>
                {(arquivo) ?
                    <Viewer ext={ext} source={arquivo} />:
                    <div>{(documento) ? t('info_nenhuma_documento'):''}</div>}
            </div>
        </>
    )
}

export default Arquivo