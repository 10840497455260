import {useEffect, useState} from "react";

export default function ContentLoader(props){
    const [show, setShow] = useState(true);
    const [msg, setMsg] = useState('');
    const [error, setError] = useState(false);

    let boxClassNames = "loader";
    let msgStyles = {width:'100%', textAlign:'center', top:'100px', position:'relative'}
    if(props.type === 'full'){
        boxClassNames = "loader-full";

    }else if(props.type === 'content'){
        boxClassNames = "loader-content";
    }else if(props.type === 'view'){
        boxClassNames = "loader-view";
    }else if(props.type === 'modal'){
        boxClassNames = "loader-modal";
    }else if(props.type === 'card'){
        boxClassNames = "loader-card";
        msgStyles.top = '10px';
    }


    useEffect(()=>{
        setShow(props.show);
        setMsg(props.msg);
        setError(props.error);
    },[props])

    return (
        <div className={boxClassNames} style={(show) ? (props.type === 'list') ? {opacity: 0.8, display:'block'} : {opacity: 1, display:'block'} :  {display:'none'}}>
            {(!error) ?
                <div style={msgStyles}>
                    {(() => {
                        return <div className="lds-facebook"><div></div><div></div><div></div></div>
                    })()}
                    <p className="pt-2 text-muted">{!msg ? '' : msg}</p>
                </div>
                :
                <div style={msgStyles}>
                    <p style={{fontSize:"80px", fontWeight:"bold"}}>:(</p>
                    <p className="pt-2 text-muted">{!msg ? '':msg}</p>
                </div>
            }
        </div>


    )
}