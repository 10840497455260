import {Divider, Steps} from 'rsuite';
import {useWizard} from "react-use-wizard";

export default function WizHeader({steps, current}){

    const {activeStep} = useWizard();
    return (
        <>
            <div className="p-3 pb-0">
                <Steps current={activeStep}>
                    {steps.map((step,i)=>{
                        return (<Steps.Item key={i} title={step.props.action} description={step.props.description} />)
                    })}
                </Steps>
            </div>
            <Divider />
        </>
    )
}