import React from 'react'
import {useTranslation} from "react-i18next";

const NotFound = props => {
    const {t} = useTranslation();
    return (
        <div>
            {t('msg_pagina_nao_encontrada')}
        </div>
    )
}

export default NotFound