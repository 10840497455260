import React, {useContext, useEffect, useRef} from 'react'
import {useTranslation} from "react-i18next";
import $ from "jquery";
import ControlContext from "../../context/ControlContext";

const Cadastro = props => {
    const {t} = useTranslation();

    const {setLeftSidebar} = useContext(ControlContext);

    useEffect(() => {
        setLeftSidebar({
            "professores": {"title": t('profissionais')},
            "escolas": {"title": t('escolas')},
            "cargos": {"title": t('cargos')}
        });
        return ()=> {setLeftSidebar(null)}
    }, []);


    return (
        <div></div>
    )
}

export default Cadastro