import React, {useState, useEffect, useContext} from 'react'
import {useTranslation} from "react-i18next";
import Loader from "../Loader";

import ProcessosContent from "../ProcessosContent";

import ServicoContext from "../../context/ServicoContext";
import ErrorPage from "./ErrorPage";

const Home = props => {
    const {t} = useTranslation();
    const [loader, setLoader] = useState({show:true, msg:t('carregando'), error:false})
    const {listarCadastro, loading: loadingServico, error: errorServico} = useContext(ServicoContext)

    useEffect(()=>{
        const init = async ()=>{
            await listarCadastro("escolas", {}, {nome:1});
            await listarCadastro("cargos", {}, {nome:1});
            await listarCadastro("professores", {}, {nome:1});
        }
        init().catch(err=>{
            console.error(err);
        })
    },[])

    useEffect(()=>{
        if(loadingServico) setLoader({show:true, msg:t('carregando')})
        else setLoader({show:false, msg:''})
    },[loadingServico])

    return (
        <>
            <Loader show={loader.show} msg={loader.msg} error={loader.error} type="content"/>
            {(errorServico) ? <ErrorPage msg={t('error_problema_listar_processo')} /> : <ProcessosContent />}
        </>
    )
}

export default Home