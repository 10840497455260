import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import $ from "jquery";
import {useTranslation} from "react-i18next";
import AuthContext from "../../context/AuthContext";

const RightSidebar = forwardRef((props, ref) => {

    const {t} = useTranslation();

    useImperativeHandle(
        ref,
        () => ({

        })
    )

    const {user} = useContext(AuthContext);


    const divRef = useRef();

    useEffect(()=>{
        $('.sidebar-right-mobile-expand').unbind('click').click(function (e){
            e.preventDefault();
            const $sidebar = $(this).parents('.sidebar');
            const sidebarFullscreenClass = 'sidebar-fullscreen';
            if(!$sidebar.hasClass(sidebarFullscreenClass)) {
                $sidebar.addClass(sidebarFullscreenClass);
            }else {
                $sidebar.removeClass(sidebarFullscreenClass);
            }
        });
        $('.sidebar-mobile-right-toggle').on('click', function (e) {
            e.preventDefault();
            $('body').toggleClass('sidebar-mobile-right').removeClass('sidebar-mobile-main sidebar-mobile-secondary');

            // Hide sidebar if in fullscreen mode on mobile
            const $sidebar = $('.sidebar-right');
            if($sidebar.hasClass('sidebar-fullscreen')) {
                $sidebar.removeClass('sidebar-fullscreen');
            }
        });
    },[])

    return (
        <>
            <div ref={divRef} className="sidebar sidebar-light bg-light sidebar-right sidebar-expand-md"
                 style={{
                     overflowY:'auto',
                     borderBottom:'1px solid rgba(0, 0, 0, 0.125)'
                }}>
                <div className="sidebar-mobile-toggler text-center">
                    <a href="#" className="sidebar-mobile-expand sidebar-right-mobile-expand">
                        <i className="icon-screen-full"></i>
                        <i className="icon-screen-normal"></i>
                    </a>
                    <span className="font-weight-semibold"></span>
                    <a href="#" className="sidebar-mobile-right-toggle">
                        <i className="icon-arrow-right8"></i>
                    </a>
                </div>
                <div className="sidebar-content bg-white"></div>
            </div>
        </>
    )
})
export default RightSidebar