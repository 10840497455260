import React, {useContext, useEffect, useRef, useState} from 'react'
import {useTranslation} from "react-i18next";
import ControlContext from "../../context/ControlContext";
import Crud from "../util/Crud";
import ServicoContext from "../../context/ServicoContext";
import ContentLoader from "../util/ContentLoader";

const Professores = props => {
    const {t} = useTranslation();

    const id = "professores";

    const {setLeftSidebar} = useContext(ControlContext);
    const {getStateCollection, listarCadastro} = useContext(ServicoContext);
    const [loader, setLoader] = useState({show:true, msg:"", error:false});

    const [data, setData] = useState([]);

    useEffect(() => {
        const init = async () => {
            await listarCadastro(id);
        }
        init().catch(err => {
            console.error(err);
        })
        setLeftSidebar({
            "professores": {"title": t('profissionais')},
            "escolas": {"title": t('escolas')},
            "cargos": {"title": t('cargos')}
        });
        return ()=> {
            setLeftSidebar(null)
            setLoader({show:true, msg:"", error: false})
        }
    }, []);

    useEffect(()=>{
        const list = getStateCollection(id);
        //console.log(id, "Atualizando Dados", list);
        setData(list);
        setTimeout(()=>{
            setLoader({show:false, msg:"", error: false})
        },500)
    },[getStateCollection(id)])


    return (
        <>
            <ContentLoader show={loader.show} msg={loader.msg} error={loader.error} type="view"/>
            <div className="mt-2" style={{height: "90%", overflowY: "auto"}}>
                <Crud
                    id={id}
                    name={t("profissionais")}
                    data={data}
                    focus={"cpf"}
                    initialSort={[{column:"nome", dir:"asc"}]}
                    persistTable={true}
                    configuration={{
                        "description": "",
                        "table": [
                            {"title":"_id", "field":"_id", "visible":false, "_type":"id"},
                            {"title":t('cpf'), "field":"cpf", "editor":"input", _type:"cpf"},
                            {"title":t('nome'), "field":"nome", "editor":"input"},
                            {"title":t('matricula'), "field":"matricula", "editor":"input"},
                            {"title":t('escola'), "field":"escola", "editor":"input", _type:"reference", _crud: "escolas", _crud_label:"nome", _empty:true},
                            {"title":t('cargo'), "field":"cargo", "editor":"input", _type:"reference", _crud: "cargos", _crud_label:"nome", _empty:true},
                            {"title":t('email'), "field":"email", "editor":"input", _type:"email"},
                            {"title":t('telefone'), "field":"telefone", "editor":"input", _type:"phone"}
                        ]
                    }}
                />
            </div>
        </>
    )
}

export default Professores