import { motion } from 'framer-motion';
import {useEffect, useState} from "react";

export default function Loader(props){
    const [show, setShow] = useState(false);
    const [msg, setMsg] = useState('');
    const [error, setError] = useState(false);

    let boxClassNames = "loader";
    let msgStyles = {width:'100%', textAlign:'center', top:'100px', position:'relative'}
    if(props.type === 'content'){
        boxClassNames = "loader-content";
    }else if(props.type === 'grid'){
        boxClassNames = "loader-grid";
    }else if(props.type === 'modal'){
        boxClassNames = "rounded loader-modal";
    }else if(props.type === 'box'){
        boxClassNames = "loader-box";
        msgStyles = {width:'100%', textAlign:'center', top:'10px', position:'relative'}
    }

    useEffect(()=>{
        setShow(props.show);
        setMsg(props.msg);
        setError(props.error);
    },[props])

    return (
        <div className={boxClassNames} style={(show) ? {display:'block'} :  {display:'none'}}>
            {(!error) ?
                <div style={msgStyles}>
                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                    <p className="pt-2 text-muted">{!msg ? '':msg}</p>
                </div>
                :
                <div style={msgStyles}>
                    <p style={{fontSize:"80px", fontWeight:"bold"}}>:(</p>
                    <p className="pt-2 text-danger text-muted">{!msg ? '':msg}</p>
                </div>
            }
        </div>
    )
}